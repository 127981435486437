import { Dispatch } from 'redux'

import { http } from '../../../utils'
import { tokenAreaConfig } from '../../auth/actions'
import { returnErrors, setAlert } from '../../support/actions'
import { DATA_LOADED, DATA_LOADING } from '../../support/types'
import {
  ACTIVATE_PAYMENT_METHOD, ADD_PAYMENT_METHOD,
  DEACTIVATE_PAYMENT_METHOD, DELETE_PAYMENT_METHOD, EDIT_PAYMENT_METHOD,
  GET_ACTIVE_PAYMENT_LIST,
  GET_PAYMENT_LIST,
  GET_TRASHED_PAYMENT_LIST, RESTORE_PAYMENT_METHOD
} from "./types";

const area = JSON.parse(localStorage.getItem('area') || '{}')

// GET PAYMENT LIST
export const getPaymentList = () => (dispatch: Dispatch) => {
  dispatch({ type: DATA_LOADING })
  try {
    const response = http(`${area.server}/payment`, { headers: tokenAreaConfig() })

    response.then(res => {
      if (res.error) {
        dispatch(returnErrors({ msg: res.message, status: 403 }))
      } else {
        dispatch({
          type: GET_PAYMENT_LIST,
          payload: res.data
        })
      }
      dispatch({ type: DATA_LOADED })
    })
  } catch (err) {
    throw new Error(err)
  }
}

// GET ACTIVE PAYMENT LIST
export const getActivePaymentList = () => (dispatch: Dispatch <any>) => {
  dispatch({ type: DATA_LOADING })
  if (Object.keys(area).length === 0) {
    dispatch(setAlert('List Payment gagal diambil, harap Reload data', 'danger'))
  }
  try {
    const response = http(`${area.server}/payment`, { headers: tokenAreaConfig() })

    response.then(res => {
      if (res.error) {
        dispatch(returnErrors({ msg: res.message, status: 403 }))
      } else {
        dispatch({
          type: GET_ACTIVE_PAYMENT_LIST,
          payload: res.data
        })
      }

      dispatch({ type: DATA_LOADED })
    })
  } catch (err) {
    throw new Error(err)
  }
}

// GET TRASHED PAYMENT LIST
export const getTrashedPaymentList = () => (dispatch: Dispatch) => {
  dispatch({ type: DATA_LOADING })
  try {
    const response = http(`${area.server}/payment/trashed`, { headers: tokenAreaConfig() })

    response.then(res => {
      if (res.error) {
        dispatch(returnErrors({ msg: res.message, status: 403 }))
      } else {
        dispatch({
          type: GET_TRASHED_PAYMENT_LIST,
          payload: res.data
        })
      }
      dispatch({ type: DATA_LOADED })
    })
  } catch (err) {
    throw new Error(err)
  }
}

// ADD PAYMENT METHOD
export const addPaymentMethod = ( name:string, code:string, description:string, steps:string) => (dispatch: Dispatch<any>) => {
  dispatch({type: DATA_LOADING})

  const data = new FormData()
  data.append('name', name)
  data.append('code', code)
  data.append('description', description)
  data.append('steps', steps)

  try {
    const response = http(`${area.server}/payment`, { method: 'post', headers: tokenAreaConfig('undefined'), body: data })

    response.then(res => {
      if (res.error) {
        dispatch(returnErrors({ msg: res.message, status: 403 }))
      } else {
        dispatch({
          type: ADD_PAYMENT_METHOD,
          payload: res.data
        })
        dispatch(setAlert('Payment berhasil ditambahkan', 'success'))
      }
      dispatch({ type: DATA_LOADED })
    })
  } catch (err) {
    throw new Error(err)
  }
}


// EDIT PAYMENT METHOD
export const editPaymentMethod = ( paymentID:string, name:string, code:string, description:string, steps:string ) => (dispatch: Dispatch<any>) => {
  dispatch({type: DATA_LOADING})

  const data = new FormData()
  data.append('name', name)
  data.append('code', code)
  data.append('description', description)
  data.append('steps', steps)

  try {
    const response = http(`${area.server}/payment/${paymentID}`, { method: 'put', headers: tokenAreaConfig('undefined'), body: data })

    response.then(res => {
      if (res.error) {
        dispatch(returnErrors({ msg: res.message, status: 403 }))
      } else {
        dispatch({
          type: EDIT_PAYMENT_METHOD,
          payload: res.data
        })
        dispatch(setAlert('Payment berhasil diedit', 'success'))
      }
      dispatch({ type: DATA_LOADED })
    })
  } catch (err) {
    throw new Error(err)
  }
}


// ACTIVATE/DEACTIVATE PAYMENT METHOD
export const toggleActivatePaymentMethod = (paymentID: string, status: any) => (dispatch: Dispatch<any>) => {
  dispatch({type: DATA_LOADING})
  const data = new FormData
  data.append('enabled', status)
  try {
    const response = http(`${area.server}/payment/${paymentID}/visibility`, {
      method: 'put',
      headers: tokenAreaConfig('undefined'),
      body: data
    })

    response.then(res => {
      if (res.error) {
        dispatch(returnErrors({ msg: res.message, status: 403 }))
      } else {
        if (status) {
          dispatch({
            type: ACTIVATE_PAYMENT_METHOD,
            payload: res.data
          })
          dispatch(setAlert('Payment berhasil diaktifkan', 'success'))
        } else {
          dispatch({
            type: DEACTIVATE_PAYMENT_METHOD,
            payload: res.data
          })
          dispatch(setAlert('Payment berhasil dinonaktifkan', 'success'))
        }
      }
      dispatch({ type: DATA_LOADED })
    })
  } catch (err) {
    throw new Error(err)
  }
}

// SOFT DELETE PAYMENT METHOD
export const deletePaymentMethod = (paymentID: string) => (dispatch: Dispatch<any>) => {
  dispatch({type: DATA_LOADING})
  try {
    const response = http(`${area.server}/payment/${paymentID}`, { headers: tokenAreaConfig(), method: 'delete' })

    response.then(res => {
      if (res.error) {
        dispatch(returnErrors({ msg: res.message, status: 403 }))
      } else {
        dispatch({
          type: DELETE_PAYMENT_METHOD,
          payload: res.data
        })
        dispatch(setAlert('Payment berhasil dihapus', 'success'))
      }
      dispatch({ type: DATA_LOADED })
    })
  } catch (err) {
    throw new Error(err)
  }
}

// RESTORE PAYMENT METHOD
export const restorePaymentMethod = (paymentID: string) => (dispatch: Dispatch<any>) => {
  dispatch({type: DATA_LOADING})
  try {
    const response = http(`${area.server}/payment/trashed/${paymentID}`, { method: 'post', headers: tokenAreaConfig() })

    response.then(res => {
      if (res.error) {
        dispatch(returnErrors({ msg: res.message, status: 403 }))
      } else {
        dispatch({
          type: RESTORE_PAYMENT_METHOD,
          payload: res.data
        })
        dispatch(setAlert('Payment berhasil dipulihkan', 'success'))
      }
      dispatch({ type: DATA_LOADED })
    })
  } catch (err) {
    throw new Error(err)
  }
}

// PERMANENT DELETE PAYMENT METHOD
export const permanentDeletePaymentMethod = (paymentID: string) => (dispatch: Dispatch<any>) => {
  dispatch({type: DATA_LOADING})
  try {
    const response = http(`${area.server}/payment/${paymentID}/permanent`, { headers: tokenAreaConfig(), method: 'delete' })

    response.then(res => {
      if (res.error) {
        dispatch(returnErrors({ msg: res.message, status: 403 }))
      } else {
        dispatch(getTrashedPaymentList())
        dispatch(setAlert('Payment berhasil dihapus permanen', 'success'))
      }
      dispatch({ type: DATA_LOADED })
    })
  } catch (err) {
    throw new Error(err)
  }
}
