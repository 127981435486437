import { Dispatch } from "redux";
import "firebase/database";
import firebaseWhatsapp from "../../../utils/firebaseWhatsapp";
import { GET_WHATSAPP_CHAT, GET_WHATSAPP_STATUS } from "./types";
import { setAlert } from "../../support/actions";

const audio = new Audio("https://myoffice.ipiring.com/whatsap_web.mp3");

export const getWhatsappStatus = () => (dispatch: Dispatch) => {
  const statusRef = firebaseWhatsapp.database().ref("whatsapp/status");
  statusRef.on("value", (snapshot) => {
    if (snapshot.exists()) {
      dispatch({
        type: GET_WHATSAPP_STATUS,
        payload: snapshot.val()
      });
    }
  });
};

export const getWhatsappChats = () => (dispatch: Dispatch<any>) => {
  const statusRef = firebaseWhatsapp.database().ref("whatsapp/chats");
  statusRef.on("value", (snapshot) => {
    if (snapshot.exists()) {
      let temp = snapshot.val();
      let sorted: any = [];
      for (let key of Object.keys(temp)) {
        temp[key].id = key;
        sorted.push(temp[key]);
      }
      sorted = sorted.sort((a: any, b: any) => {
        let x = a.updatedAt || 0;
        let y = b.updatedAt || 0;
        return y - x;
      });
      sorted = sorted.filter((item: any) => item && item.hasOwnProperty("chats") && item.hasOwnProperty("contact"));
      const lastItem = sorted[0].chats;
      const itemKey = Object.keys(lastItem).pop();
      // @ts-ignore
      if (!lastItem[itemKey].fromMe) {
        audio.play();
        // dispatch(setAlert("Pesan baru di Whatsapp Chat Log", "success", 2500));
      }
      dispatch({
        type: GET_WHATSAPP_CHAT,
        payload: sorted
      });
    }
  });
};
