import { Media } from '../food/types'
import { Variant } from '../food/variant/types'
import { FoodCategory } from '../foodCategory/types'

export const GET_STOCK_LIST = 'GET_STOCK_LIST'
export const SET_CURRENT_STOCK = 'SET_CURRENT_STOCK'
export const EDIT_STOCK = 'EDIT_STOCK'
export const RESET_STOCK = 'RESET_STOCK'
export const STOCK_GENERATE = 'STOCK_GENERATE'
export const GET_REPORT_STOCK_LIST = 'GET_REPORT_STOCK_LIST'

export interface VariantInStock extends Variant {
  stock: number
  instock: number
  stokDasar : number
  order : number
  stock_id: string
}

export interface ExtraInStock extends VariantInStock {}

export interface StockType {
  _id: string
  nama: string
  sku: string
  gambar: Media[]
  varian: VariantInStock[]
  extra: null | ExtraInStock[]
  date: string
  kategori: FoodCategory
}

export interface ReportStockType {
  sku: string
  nama: string
  instock: number
}

export interface GetListType {
  type: typeof GET_STOCK_LIST
  payload: StockType[]
}

export interface EditStockType {
  type: typeof EDIT_STOCK
  payload: StockType
}

export interface ResetStockType {
  type: typeof RESET_STOCK
  payload: StockType
}

export interface GenerateStockType {
  type: typeof STOCK_GENERATE
  payload: StockType[]
}

export interface SetCurrentStockType {
  type: typeof SET_CURRENT_STOCK
  payload: {
    variants: VariantInStock[]
    extras: ExtraInStock[]
    category: FoodCategory
  }
}

export interface GetReportStockType {
  type: typeof GET_REPORT_STOCK_LIST
  payload: ReportStockType[]
}

export interface StockState {
  stock_list: StockType[]
  selected_stock: {
    variants: VariantInStock[] | any
    extras: ExtraInStock[] | any
    category: FoodCategory | any
  }
  report_stock_list: ReportStockType[]
}

export type StockActionType =
  | GetListType
  | EditStockType
  | ResetStockType
  | GenerateStockType
  | SetCurrentStockType
  | GetReportStockType
