import Payment from "../../../components/views/BackOffice/Payment/Payment";

export const ACTIVATE_PAYMENT_METHOD = 'ACTIVATE_PAYMENT_METHOD'
export const ADD_PAYMENT_METHOD = 'ADD_PAYMENT_METHOD'
export const DEACTIVATE_PAYMENT_METHOD = 'DEACTIVATE_PAYMENT_METHOD'
export const DELETE_PAYMENT_METHOD = 'DELETE_PAYMENT_METHOD'
export const EDIT_PAYMENT_METHOD = 'EDIT_PAYMENT_METHOD'
export const GET_ACTIVE_PAYMENT_LIST = 'GET_ACTIVE_PAYMENT_LIST'
export const GET_PAYMENT_LIST = 'GET_PAYMENT_LIST'
export const GET_TRASHED_PAYMENT_LIST = 'GET_TRASHED_PAYMENT_LIST'
export const RESTORE_PAYMENT_METHOD = 'RESTORE_PAYMENT_METHOD'

export interface Detail {
  name: string,
  stakeholder: string,
  number: string,
  icon: string
}

export interface Payment {
  enabled: boolean,
  deleted: boolean,
  _id: string,
  code: string,
  description: string,
  name: string,
  order: number,
  detail?: Detail[]
  __v: number
}

export interface GetPaymentListType {
  type: typeof GET_PAYMENT_LIST
  payload: Payment[]
}

export interface GetActivePaymentListType {
  type: typeof GET_ACTIVE_PAYMENT_LIST
  payload: Payment[]
}

export interface GetTrashedPaymentListType {
  type: typeof GET_TRASHED_PAYMENT_LIST
  payload: Payment[]
}

export interface AddPaymentMethodType {
  type: typeof ADD_PAYMENT_METHOD
  payload: Payment
}

export interface EditPaymentMethodType {
  type: typeof EDIT_PAYMENT_METHOD
  payload: Payment
}

export interface ActivatePaymentMethodType {
  type: typeof ACTIVATE_PAYMENT_METHOD
  payload: Payment
}

export interface DeactivatePaymentMethodType {
  type: typeof DEACTIVATE_PAYMENT_METHOD
  payload: Payment
}

export interface DeletePaymentMethodType {
  type: typeof DELETE_PAYMENT_METHOD
  payload: Payment
}

export interface RestorePaymentMethodType {
  type: typeof RESTORE_PAYMENT_METHOD
  payload: Payment
}

export interface PaymentState {
  payment_list: Payment[]
  active_payment_list: Payment[]
  trashed_payment_list: Payment[]
}

export type PaymentActionType =
  | GetPaymentListType
  | GetActivePaymentListType
  | GetTrashedPaymentListType
  | AddPaymentMethodType
  | EditPaymentMethodType
  | ActivatePaymentMethodType
  | DeactivatePaymentMethodType
  | DeletePaymentMethodType
  | RestorePaymentMethodType
