import React, { useEffect, useState } from "react";
import {
  CButton,
  CCol,
  CForm,
  CFormGroup,
  CInput,
  CInputGroup,
  CLabel,
  CModal,
  CModalBody, CModalFooter,
  CModalHeader,
  CRow
} from "@coreui/react";
import { useDispatch } from "react-redux";
import { addPaymentMethod, editPaymentMethod } from "../../../../redux/backOffice/payment/actions";
import TextEditor from '../../../Additional/TextEditor'

type PaymentFormProps = {
  formOpen : boolean,
  setFormOpen : any,
  initialState : any,
  actionStatus : string,
  addCallback : any
}

const PaymentForm: React.FC<PaymentFormProps> = ({formOpen, setFormOpen, initialState, actionStatus, addCallback}) => {

  const [paymentID, setPaymentID] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [steps, setSteps] = useState<any>('')

  const dispatch = useDispatch()

  useEffect(() => {
    setPaymentID(initialState.id)
    setName(initialState.name)
    setCode(initialState.code)
    setDescription(initialState.description)
    setSteps(initialState.steps)
  }, [initialState])

  const handleSubmit = () => {
    if (actionStatus === 'Tambah') {
      dispatch(addPaymentMethod(name, code, description, steps))
    } else {
      dispatch(editPaymentMethod(paymentID, name, code, description, steps))
    }
    addCallback()
  }

  return(
    <CModal
      show={formOpen}
      onClose={() => {
        setFormOpen(!formOpen)
        addCallback()
      }}
      centered size={'lg'}
    >
      <CModalHeader closeButton>{actionStatus} Payment</CModalHeader>
      <CModalBody>
        <CForm>
          <CRow form>
            <CCol md="6">
              <CFormGroup>
                <CLabel htmlFor="name">Nama Payment</CLabel>
                <CInputGroup>
                  <CInput
                    id="name"
                    type="text"
                    name="name"
                    value={name}
                    onChange={(event: any) => setName(event.target.value)}
                  />
                </CInputGroup>
              </CFormGroup>
            </CCol>
            <CCol md="6">
              <CFormGroup>
                <CLabel htmlFor="code">Kode Payment</CLabel>
                <CInputGroup>
                  <CInput
                    id="code"
                    type="text"
                    name="code"
                    value={code}
                    onChange={(event: any) => setCode(event.target.value)}
                  />
                </CInputGroup>
              </CFormGroup>
            </CCol>
          </CRow>
          <CRow form>
            <CCol md="12">
              <CFormGroup>
                <CLabel htmlFor="description">Deskripsi Payment</CLabel>
                <TextEditor editorID={"description"} editorValue={description} editorCallback={setDescription} />
              </CFormGroup>
            </CCol>
          </CRow>
          <CRow form>
            <CCol md="12">
              <CFormGroup>
                <CLabel htmlFor="steps">Step Payment</CLabel>
                <TextEditor editorID={"steps"} editorValue={steps} editorCallback={setSteps} />
              </CFormGroup>
            </CCol>
          </CRow>
        </CForm>
      </CModalBody>
      <CModalFooter>
        <CButton type="submit" color="primary" onClick={handleSubmit}>
          Masukkan
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default PaymentForm
