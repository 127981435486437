export const GET_QR_LIST = 'GET_QR_LIST'

export interface Docs {
  _id: string;
  title: string;
  description: string;
  content: string;
  hit: number;
  id: number;
  __v: number;
  link_full: string;
  link_simple: string;
}

export interface QRType {
  docs: Docs[];
  totalDocs: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: null | number;
  nextPage: null | number;
}

interface GetQRListType {
  type: typeof GET_QR_LIST
  payload: QRType
}

export interface QRState {
  qr_list: QRType
}

export type QRActionState =
  | GetQRListType
