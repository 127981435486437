export const PUSAT_URL = 'https://ipiring-pusat-4lb6sq5ubq-et.a.run.app/api-pusat'
// export const PUSAT_URL = 'https://api2.ipiring.com/api-pusat'
export const HCAPTCHA_API = '97ce4969-3b98-4a52-8b19-a8b416d46420'

export interface ConfigType {
  headers: {
    [key: string]: string
  }
}

export default async function http<TResponse = any>(input: RequestInfo, init?: RequestInit): Promise<TResponse> {
  const res = await fetch(`${input}`, init)
  const data: Promise<TResponse> = await res.json()

  return data
}
