import { Editor } from "@tinymce/tinymce-react";
import React from 'react'

interface TextEditorProps {
  editorID: string,
  editorValue: string
  editorCallback: any
}

const TextEditor:React.FC<TextEditorProps> = ({ editorID, editorValue, editorCallback }) => {
  return (
    <Editor
      id={editorID}
      value={editorValue}
      init={{
        height: 400,
        plugins: [
          'advlist','autolink', 'lists','link','image','charmap','preview','anchor','help',
          'searchreplace','visualblocks','fullscreen','insertdatetime','media','table','wordcount'
        ],
        menubar: 'file edit view insert format tools table help',
        toolbar: 'undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
        toolbar_sticky: false,
        toolbar_mode: 'sliding',
      }}
      onEditorChange={content => editorCallback(content)}
    />
  )
}

export default TextEditor
