export const GET_WHATSAPP_STATUS = "GET_WHATSAPP_STATUS";
export const GET_WHATSAPP_CHAT = "GET_WHATSAPP_CHAT";

export interface ChatsID {
  _serialized: string;
  fromMe: boolean;
  id: string;
  remote: string;
}

export interface Chats {
  [timeStamp: number]: {
    body: string
    fromMe: boolean
    hasMedia: boolean
    media: string
    id: ChatsID
    isGroup: boolean
    timestamp: number
    type: string
  };
}

export interface ContactID {
  _serialized: string;
  server: string;
  user: string;
}

export interface Contact {
  id: ContactID;
  isMyContact: boolean;
  isUser: boolean;
  isWAContact: boolean;
  isGroup: boolean;
  name: string;
  number: string;
  pushname: string;
  shortName: string;
  type: string;
}

export interface WhatsappChat {
  [userID: string]: {
    chats: Chats
    contact: Contact
    profile: string
    updatedAt: number
  };
}

interface GetWhatsappChatsType {
  type: typeof GET_WHATSAPP_CHAT;
  payload: WhatsappChat;
}

interface GetWhatsappStatusType {
  type: typeof GET_WHATSAPP_STATUS;
  payload: any;
}

export interface WhatsappAPIState {
  whatsapp_chats: WhatsappChat;
  whatsapp_status: any;
}

export type WhatsappAPIActionType =
  | GetWhatsappChatsType
  | GetWhatsappStatusType
