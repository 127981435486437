import {
  GreetingActionType,
  GreetingState,
  GET_GREETING_LIST
} from "./types";

const initialState: GreetingState = {
  greeting_list: []
}

export default function(state= initialState, action: GreetingActionType): GreetingState {
  switch (action.type) {
    case GET_GREETING_LIST:
      return {
        ...state,
        greeting_list: action.payload
      }
    default:
      return state
  }
}
