import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../redux/rootReducer";
import { CDataTable, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilDelete, cilPen } from "@coreui/icons";
import {
  permanentDeletePaymentMethod,
  restorePaymentMethod
} from "../../../../redux/backOffice/payment/actions";
import DeleteConfirmation from "../../../Additional/DeleteConfirmation";

const TabSampah: React.FC = () => {

  const [deleteForm, setDeleteForm] = useState(false)
  const [deleteState, setDeleteState] = useState<any>({
    itemID: '',
    itemName: '',
    category: ''
  })

  const dispatch = useDispatch()
  const trashedPaymentList = useTypedSelector(state => state.payment.trashed_payment_list)
  const isLoading = useTypedSelector(state => state.support.isLoading)

  const handleDelete = (item: any) => {
    setDeleteState({
      itemID: item.id,
      itemName: item.name,
      category: 'Permanen Payment'
    })
    setDeleteForm(true)
  }

  const deleteCallback = (itemID: string, status: boolean) => {
    if (status) {
      dispatch(permanentDeletePaymentMethod(itemID))
    }
  }

  const datatable = trashedPaymentList.map((payment, index) => ({
    id: payment._id,
    num: index+1,
    code: payment.code,
    name: payment.name,
    desc: payment.description,
    payment
  }))

  const fields = [
    {key: 'num', label: 'No', _style: { width: '1%' } },
    {key: 'code', label: 'Kode', _style: { width: '10%' } },
    {key: 'name', label: 'Nama', _style: { width: '20%' } },
    {key: 'desc', label: 'Deskripsi', _style: { width: '40%' } },
    {
      key: 'action',
      label: 'Aksi',
      sorter: false,
      filter: false,
      _style: { width: '5%' }
    }
  ]

  return(
    <>
      <CDataTable
        items={datatable}
        fields={fields}
        tableFilter
        itemsPerPageSelect
        itemsPerPage={5}
        loading={isLoading === 'loading'}
        sorter
        hover
        striped
        border
        pagination
        responsive
        scopedSlots={{
          desc: (item: { desc: string; id: string }) => (
            <td className="py-2">
              <div dangerouslySetInnerHTML={{ __html: item.desc }} />
            </td>
          ),
          action: (item: { id: string }, index: number) => (
            <td className="py-2">
              <CDropdown>
                <CDropdownToggle color="info">Aksi</CDropdownToggle>
                <CDropdownMenu color="info" placement="right-start">
                  <CDropdownItem
                    onClick={() => {
                      dispatch(restorePaymentMethod(item.id))
                    }}
                  >
                    <CIcon content={cilPen} className="mfe-2" /> Pulihkan
                  </CDropdownItem>
                  <CDropdownItem onClick={() => handleDelete(item)} style={{color: 'red'}}>
                    <CIcon content={cilDelete} className="mfe-2" /> Hapus Permanen
                  </CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
            </td>
          )
        }}
      />
      <DeleteConfirmation formOpen={deleteForm} setFormOpen={setDeleteForm} itemID={deleteState.itemID}
                          itemName={deleteState.itemName} category={deleteState.category}
                          addCallback={deleteCallback} />
    </>
  )
}

export default TabSampah
