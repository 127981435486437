import {
  GET_QR_LIST,
  QRActionState,
  QRState
} from './types'

const defaultList = {
  docs: [],
  totalDocs: 0,
  limit: 0,
  page: 1,
  totalPages: 1,
  pagingCounter: 1,
  hasPrevPage: false,
  hasNextPage: false,
  prevPage: null,
  nextPage: null
}

const initialState: QRState = {
  qr_list: defaultList
}

export default function(state= initialState, action: QRActionState): QRState {
  switch (action.type) {
    case GET_QR_LIST:
      return {
        ...state,
        qr_list: action.payload
      }
    default:
      return state
  }
}
