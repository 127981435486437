import {
  CANCEL_ORDER,
  GET_AVAILABLE_DRIVER_LIST,
  GET_ORDER,
  GET_ORDER_CATEGORY_LIST,
  GET_ORDER_LIST,
  GET_SEARCH_KEY_ORDER_CATEGORY_LIST,
  OrderActionType,
  OrderCategoriesListType,
  OrderState,
  REFUND,
  SearchKeyOrderCategoryListType
} from './types'

const defaultList = {
  docs: [],
  totalDocs: 0,
  limit: 0,
  page: 1,
  totalPages: 1,
  pagingCounter: 1,
  hasPrevPage: false,
  hasNextPage: false,
  prevPage: null,
  nextPage: null
}

const initialState: OrderState = {
  order_list: defaultList,
  order: null,
  order_categories : {},
  search_key_order_category: {},
  driver_list: []
}

export default function (state = initialState, action: OrderActionType): OrderState {
  switch (action.type) {
    case GET_ORDER_LIST:
      return {
        ...state,
        order_list: action.payload
      }
    case REFUND:
    case CANCEL_ORDER:
    /*case EDIT_STATUS_ORDER:
      return {
        ...state,
        order_list: state.order_list.docs.map(order => order._id === action.payload._id ? action.payload : order)
      }*/
    case GET_ORDER:
      return {
        ...state,
        order: action.payload
      }
    case GET_ORDER_CATEGORY_LIST:
      let baru: OrderCategoriesListType = {};
      baru[action.key] = action.payload;
      return {
        ...state,
        order_categories: {
          ...state.order_categories,
          ...baru
        }
      };
    case GET_SEARCH_KEY_ORDER_CATEGORY_LIST:
      let newSearch: SearchKeyOrderCategoryListType = {};
      newSearch[action.key] = action.payload;
      return {
        ...state,
        search_key_order_category: {
          ...state.search_key_order_category,
          ...newSearch
        }
      }
    case GET_AVAILABLE_DRIVER_LIST:
      return {
        ...state,
        driver_list: action.payload
      }
    default:
      return state
  }
}
