import {
  APKActionType,
  APKState,
  GET_DRIVER_APK,
  GET_KITCHEN_APK,
  UPLOAD_DRIVER_APK,
  UPLOAD_KITCHEN_APK
} from "./types";

const initialState: APKState = {
  driver_apk: {
    version: "-",
    link: "-"
  },
  kitchen_apk: {
    version: "-",
    link: "-"
  }
}

export default function(state= initialState, action: APKActionType): APKState {
  switch (action.type) {
    case GET_DRIVER_APK:
      return {
        ...state,
        driver_apk: action.payload
      }
    case GET_KITCHEN_APK:
      return {
        ...state,
        kitchen_apk: action.payload
      }
    case UPLOAD_DRIVER_APK:
      return {
        ...state,
        driver_apk: action.payload
      }
    case UPLOAD_KITCHEN_APK:
      return {
        ...state,
        kitchen_apk: action.payload
      }
    default:
      return state
  }
}
