import React, { useEffect, useState } from "react";
import { CCol, CRow } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilWallet } from "@coreui/icons";
import { useDispatch } from "react-redux";
import { getPaymentList, getTrashedPaymentList } from "../../../../redux/backOffice/payment/actions";
import TabsWrapper from "./TabsWrapper";
import TabUtama from "./TabUtama";
import TabSampah from "./TabSampah";
import PaymentForm from "./PaymentForm";

const Payment: React.FC = () => {

  const [addForm, setAddForm] = useState(false)
  const [initialState, setInitialState] = useState<any>({
    id: '',
    name: '',
    code: '',
    description: '',
    steps: ''
  })
  const [actionStatus, setActionStatus] = useState<string>('Tambah')

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPaymentList())
    dispatch(getTrashedPaymentList())
  }, [])

  const handlePaymentForm = () => {
    setAddForm(prevStatus => !prevStatus)
  }

  const handlePaymentState = (paymentState: any) => {
    setInitialState({
      id: paymentState.id,
      name: paymentState.name,
      code: paymentState.code,
      description: paymentState.desc,
      steps: paymentState.steps
    })
    setActionStatus('Edit')
  }

  const addCallback = () => {
    setAddForm(false)
    setInitialState({
      id: '',
      name: '',
      code: '',
      description: '',
      steps: ''
    })
    setActionStatus('Tambah')
  }

  return(
    <>
      <CRow>
        <CCol sm={12}>
          <CRow alignVertical="center" className="my-3">
            <CCol md="6">
              <CIcon content={cilWallet} className="mfe-2" /> Daftar Payment
            </CCol>
          </CRow>
          <TabsWrapper handlePaymentForm={handlePaymentForm} >
            <div id={"Utama"}>
              <TabUtama handlePaymentForm={handlePaymentForm} handlePaymentState={handlePaymentState} />
            </div>
            <div id={"Sampah"}>
              <TabSampah />
            </div>
          </TabsWrapper>
        </CCol>
      </CRow>
      <PaymentForm formOpen={addForm} setFormOpen={setAddForm} initialState={initialState} actionStatus={actionStatus} addCallback={addCallback} />
    </>
  )
}

export default Payment
