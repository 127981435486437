import { Dispatch } from "redux";
import { http, PUSAT_URL } from "../../utils";
import { returnErrors } from "../support/actions";
import {
  REGSITER_SUCCESS,
  REGISTER_FAILED,
  LOGOUT_SUCCESS,
  LOGIN_SUCCESS,
  AUTH_ERROR,
  WHOAMI,
  LOGIN_AREA_SUCCESS,
  SWITCH_BRANCHES
} from "./types";
import { DATA_LOADED, DATA_LOADING } from "../support/types";
import { GET_FIREBASE_CUSTOM_TOKEN } from "../backOffice/chat/types";
import { getWhatsappChats, getWhatsappStatus } from "../googleFirebase/whatsappAPI/actions";
import { getDevicesMapping } from "../googleFirebase/whatsappAPIV2/actions";
import firebaseWhatsapp from "../../utils/firebaseWhatsapp";

interface RegisterParams {
  nama: string;
  email: string;
  password: string;
  password_confirmation: string;
  hp: string;
  token: string;
  redirect: string;
}

interface LoginPusatParams {
  email: string;
  password: string;
  token: string;
}

// Setup config with token pusat - helper function
export const tokenConfig = (contentType = "application/json"): Headers => {
  const token = localStorage.getItem("token_pusat");

  // Headers
  let config = new Headers();
  if (contentType !== "undefined") {
    config.append("Content-Type", contentType);
  }

  if (token) {
    config.append("Authorization", `Bearer ${token}`);
  }

  return config;
};

// Setup config with token area - helper function
export const tokenAreaConfig = (contentType = "application/json"): Headers => {
  const token = localStorage.getItem("token_area");

  // Headers
  let config = new Headers();
  if (contentType !== "undefined") {
    config.append("Content-Type", contentType);
  }

  if (token) {
    config.append("Authorization", `Bearer ${token}`);
  }

  return config;
};

export const basicAuth = (contentType = "application/json"): Headers => {
  const Username = '9b943523a83e498f748bff6ca95d5db8'
  const Password = ''

  let config = new Headers();
  if (contentType !== "undefined") {
    config.append("Content-Type", contentType);
  }
  config.append("Authorization", `Basic ` + btoa(`${Username}:${Password}`));

  return config;
}

// WHO AM I
export const whoAmI = () => (dispatch: Dispatch<any>) => {
  const response = http(`${PUSAT_URL}/auth/staff/whoami`, { headers: tokenConfig() });

  response
    .then(res => {
      if (res.error) {
        dispatch({ type: AUTH_ERROR });
        dispatch(returnErrors({ msg: res.message, status: 404 }));
      } else {
        dispatch(getFirebaseCustomToken());
        dispatch({
          type: WHOAMI,
          payload: res
        });
      }
    })
    .catch(err => {
      throw new Error(err);
    });
};

export const getFirebaseCustomToken = () => (dispatch: Dispatch<any>) => {
  try {
    const response = http(`${PUSAT_URL}/support/token`, { headers: tokenConfig() });

    response.then(res => {
      if (res.error) {
        dispatch(returnErrors({ msg: res.message, status: 403 }));
      } else {
        firebaseWhatsapp.auth().signInWithCustomToken(res.data)
          .then((userCredential) => {

            dispatch(getWhatsappStatus());
            dispatch(getWhatsappChats());
            dispatch(getDevicesMapping());
            dispatch({
              type: GET_FIREBASE_CUSTOM_TOKEN,
              payload: res.data
            });

          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  } catch (err) {
    throw new Error(err);
  }
};

// LOGIN AREA USER
export const loginArea = (id: string) => (dispatch: Dispatch) => {
  dispatch({ type: DATA_LOADING });
  const data = {
    resource: "office"
  };

  const response = http(`${PUSAT_URL}/area/access/${id}`, {
    method: "post",
    headers: tokenConfig(),
    body: JSON.stringify(data)
  });

  response
    .then(res => {
      if (res.error) {
        dispatch({ type: AUTH_ERROR });
        dispatch(returnErrors({ msg: res.message, status: 404 }));
      } else {
        dispatch({
          type: LOGIN_AREA_SUCCESS,
          payload: res.data
        });
      }
      dispatch({ type: DATA_LOADED });
    })
    .catch(err => {
      throw new Error(err);
    });
};

// LOGIN USER
export const loginPusat = (params: LoginPusatParams) => (dispatch: Dispatch) => {
  dispatch({ type: DATA_LOADING });
  const response = http(`${PUSAT_URL}/auth/staff/login`, {
    method: "post",
    body: JSON.stringify(params),
    headers: { "Content-Type": "application/json" }
  });

  response
    .then(res => {
      if (res.error) {
        dispatch({ type: AUTH_ERROR });
        dispatch(returnErrors({ msg: res.message, status: 404 }));
        window.setTimeout(function() {
          window.location.reload();
        }, 3000);
      } else {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data
        });
      }
      dispatch({ type: DATA_LOADED });
    })
    .catch(err => {
      throw new Error(err);
    });
};

// REGISTER USER
export const register = ({
                           nama,
                           email,
                           password,
                           password_confirmation,
                           hp,
                           token,
                           redirect
                         }: RegisterParams) => (dispatch: Dispatch) => {
  dispatch({ type: DATA_LOADING });
  const data = new FormData();

  data.append("nama", nama);
  data.append("email", email);
  data.append("password", password);
  data.append("password_confirmation", password_confirmation);
  data.append("hp", hp);
  data.append("token", token);
  data.append("redirect", redirect);

  const response = http(`${PUSAT_URL}/auth/staff/register`, {
    method: "post",
    body: data
  });

  response
    .then(res => {
      if (res.error) {
        dispatch(returnErrors({ msg: res.message, status: 403 }));
        dispatch({
          type: REGISTER_FAILED
        });
        window.setTimeout(function() {
          window.location.reload();
        }, 3000);
      } else {
        dispatch({
          type: REGSITER_SUCCESS,
          payload: true
        });
      }
      dispatch({ type: DATA_LOADED });
    })
    .catch(err => {
      throw new Error(err);
    });
};

// SWITCH BRANCHES
export const switchBranches = () => (dispatch: Dispatch) => {
  dispatch({
    type: SWITCH_BRANCHES
  });
  window.location.reload();
};

// LOGOUT USER
export const logout = () => (dispatch: Dispatch) => {
  dispatch({
    type: LOGOUT_SUCCESS
  });
};
