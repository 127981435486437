import {
  SuperAppBlogActionType,
  SuperAppBlogState,
  ACTIVATE_SUPER_APP_BLOG,
  ADD_SUPER_APP_BLOG,
  DEACTIVATE_SUPER_APP_BLOG,
  EDIT_SUPER_APP_BLOG,
  GET_SUPER_APP_BLOG_LIST,
  GET_SUPER_APP_TRASHED_BLOG_LIST
} from './types'

const initialState: SuperAppBlogState = {
  super_app_blog_list: [],
  super_app_trashed_blog_list: []
}

export default function(state= initialState, action: SuperAppBlogActionType): SuperAppBlogState {
  switch (action.type) {
    case GET_SUPER_APP_BLOG_LIST:
      return {
        ...state,
        super_app_blog_list: action.payload
      }
    case GET_SUPER_APP_TRASHED_BLOG_LIST:
      return {
        ...state,
        super_app_trashed_blog_list: action.payload
      }
    case ADD_SUPER_APP_BLOG:
      return {
        ...state,
        super_app_blog_list: [...state.super_app_blog_list, action.payload]
      }
    case EDIT_SUPER_APP_BLOG:
    case ACTIVATE_SUPER_APP_BLOG:
    case DEACTIVATE_SUPER_APP_BLOG:
      return {
        ...state,
        super_app_blog_list: state.super_app_blog_list.map(state => (state._id === action.payload._id ? action.payload : state))
      }
    default:
      return state
  }
}
