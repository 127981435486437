import {
  ACTIVATE_BLOG,
  ADD_BLOG,
  DEACTIVATE_BLOG,
  EDIT_BLOG,
  GET_BLOG_LIST,
  GET_TRASHED_BLOG_LIST,
  BlogActionType,
  BlogState,
} from "./types";

const initialState: BlogState = {
  blog_list: [],
  trashed_blog_list: []
}

export default function(state= initialState, action: BlogActionType ): BlogState {
  switch (action.type) {
    case GET_BLOG_LIST:
      return {
        ...state,
        blog_list: action.payload
      }
    case GET_TRASHED_BLOG_LIST:
      return {
        ...state,
        trashed_blog_list: action.payload
      }
    case ADD_BLOG:
      return {
        ...state,
        blog_list: [...state.blog_list, action.payload]
      }
    case EDIT_BLOG:
    case ACTIVATE_BLOG:
    case DEACTIVATE_BLOG:
      return {
        ...state,
        blog_list: state.blog_list.map(state => (state._id === action.payload._id ? action.payload : state))
      }
    default:
      return state
  }
}
