import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../redux/rootReducer";
import { CButton, CDataTable, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CSwitch } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilDelete, cilPen } from "@coreui/icons";
import PaymentDetail from "./PaymentDetail";
import { deletePaymentMethod, toggleActivatePaymentMethod } from "../../../../redux/backOffice/payment/actions";
import DeleteConfirmation from "../../../Additional/DeleteConfirmation";

interface Props {
  handlePaymentForm: () => void,
  handlePaymentState: any
}

const TabUtama: React.FC<Props> = ({ handlePaymentForm, handlePaymentState }) => {

  const [detailForm, setDetailForm] = useState(false)
  const [detailState, setDetailState] = useState({})
  const [deleteForm, setDeleteForm] = useState(false)
  const [deleteState, setDeleteState] = useState<any>({
    itemID: '',
    itemName: '',
    category: ''
  })

  const dispatch = useDispatch()
  const paymentList = useTypedSelector(state => state.payment.payment_list)
  const isLoading = useTypedSelector(state => state.support.isLoading)

  const handleDelete = (item: any) => {
    setDeleteState({
      itemID: item.id,
      itemName: item.name,
      category: 'Payment'
    })
    setDeleteForm(true)
  }

  const deleteCallback = (itemID: string, status: boolean) => {
    if (status) {
      dispatch(deletePaymentMethod(itemID))
    }
  }

  const datatable = paymentList.map((payment, index) => ({
    id: payment._id,
    num: index+1,
    code: payment.code,
    name: payment.name,
    desc: payment.description,
    enabled: payment.enabled,
    detail: payment.detail,
    payment
  }))

  const fields = [
    {key: 'num', label: 'No', _style: { width: '1%' } },
    {key: 'code', label: 'Kode', _style: { width: '10%' } },
    {key: 'name', label: 'Nama', _style: { width: '20%' } },
    {key: 'desc', label: 'Deskripsi', _style: { width: '40%' } },
    {key: 'enabled', label: 'Status', _style: { width: '10%' } },
    {
      key: 'detail',
      label: 'Detail',
      sorter: false,
      filter: false,
      _style: { width: '5%' }
    },
    {
      key: 'action',
      label: 'Aksi',
      sorter: false,
      filter: false,
      _style: { width: '5%' }
    }
  ]

  return(
    <>
      <CDataTable
        items={datatable}
        fields={fields}
        tableFilter
        itemsPerPageSelect
        itemsPerPage={5}
        loading={isLoading === 'loading'}
        sorter
        hover
        striped
        border
        pagination
        responsive
        scopedSlots={{
          desc: (item: { desc: string; id: string }) => (
            <td className="py-2">
              <div dangerouslySetInnerHTML={{ __html: item.desc }} />
            </td>
          ),
          enabled: (item: { enabled: boolean; id: string }) => (
            <td className="py-2">
              <CSwitch
                className="mx-1"
                size="sm"
                shape="pill"
                color="success"
                labelOn={'\u2713'}
                labelOff={'\u2715'}
                checked={item.enabled}
                onChange={() => {
                  dispatch(toggleActivatePaymentMethod(item.id, !item.enabled))
                }}
              />
            </td>
          ),
          detail: (item: { detail: object }, index: number) => (
            <td className="py-2">
              <CButton
                color={item.detail === undefined ? "secondary" : "info"}
                disabled={item.detail === undefined}
                onClick={() => {
                  setDetailForm(true)
                  setDetailState(item)
                }}
              >
                Detail
              </CButton>
            </td>
          ),
          action: (item: { id: string }, index: number) => (
            <td className="py-2">
              <CDropdown>
                <CDropdownToggle color="info">Aksi</CDropdownToggle>
                <CDropdownMenu color="info" placement="right-start">
                  <CDropdownItem
                    onClick={() => {
                      handlePaymentForm()
                      handlePaymentState(item)
                    }}
                  >
                    <CIcon content={cilPen} className="mfe-2" /> Edit
                  </CDropdownItem>
                  <CDropdownItem onClick={() => handleDelete(item)} style={{color: 'red'}}>
                    <CIcon content={cilDelete} className="mfe-2" /> Hapus
                  </CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
            </td>
          )
        }}
      />
      <PaymentDetail formOpen={detailForm} setFormOpen={setDetailForm} initialState={detailState} />
      <DeleteConfirmation formOpen={deleteForm} setFormOpen={setDeleteForm} itemID={deleteState.itemID}
                          itemName={deleteState.itemName} category={deleteState.category}
                          addCallback={deleteCallback} />
    </>
  )
}

export default TabUtama
