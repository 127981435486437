export const GET_REQUEST_URL = 'GET_REQUEST_URL'
export const GET_HEATMAP_DATA = 'GET_HEATMAP_DATA'

export interface RequestURLType {
  url: string
}

export interface GetRequestURLType {
  type: typeof GET_REQUEST_URL
  payload: RequestURLType
}

export interface GetHeatmapDataType {
  type: typeof GET_HEATMAP_DATA
  payload: []
}

export interface ReportState {
  requestURL: RequestURLType
  heatmapData: []
}

export type ReportActionType =
  | GetRequestURLType
  | GetHeatmapDataType
