export const GET_DRIVER_APK = 'GET_DRIVER_APK'
export const GET_KITCHEN_APK = 'GET_KITCHEN_APK'
export const UPLOAD_DRIVER_APK = 'UPLOAD_DRIVER_APK'
export const UPLOAD_KITCHEN_APK = 'UPLOAD_KITCHEN_APK'

export interface APK {
  deleted?: boolean;
  _id?: string;
  key?: string;
  __v?: number;
  createdAt?: string;
  link: string;
  updatedAt?: string;
  version: string;
}

export interface GetDriverAPKType {
  type: typeof GET_DRIVER_APK
  payload: APK
}

export interface GetKitchenAPKType {
  type: typeof GET_KITCHEN_APK
  payload: APK
}

export interface UploadDriverAPKType {
  type: typeof UPLOAD_DRIVER_APK
  payload: APK
}

export interface UploadKitchenAPKType {
  type: typeof UPLOAD_KITCHEN_APK
  payload: APK
}

export interface APKState {
  driver_apk: APK,
  kitchen_apk: APK
}

export type APKActionType =
  | GetDriverAPKType
  | GetKitchenAPKType
  | UploadDriverAPKType
  | UploadKitchenAPKType
