import { GET_HEATMAP_DATA, GET_REQUEST_URL, ReportActionType, ReportState } from "./types";

const initialState: ReportState = {
  requestURL: {url: ''},
  heatmapData: []
}

export default function(state = initialState, action: ReportActionType): ReportState {
  switch (action.type) {
    case GET_REQUEST_URL:
      return {
        ...state,
        requestURL: action.payload
      }
    case GET_HEATMAP_DATA:
      return {
        ...state,
        heatmapData: action.payload
      }
    default:
      return state
  }
}
