import {
  ExtraState,
  ExtraActionType,
  GET_EXTRA_LIST,
  ADD_EXTRA,
  EDIT_EXTRA,
  UPLOAD_EXTRA_IMAGE,
  REMOVE_EXTRA_IMAGE,
  DELETE_EXTRA,
  REORDER_EXTRA
} from './types'

const initialState: ExtraState = {
  extra_list: []
}

export default function(state = initialState, action: ExtraActionType): ExtraState {
  switch (action.type) {
    case REORDER_EXTRA:
    case GET_EXTRA_LIST:
      return {
        ...state,
        extra_list: action.payload
      }
    case ADD_EXTRA:
      return {
        ...state,
        extra_list: [...state.extra_list, action.payload]
      }
    case REMOVE_EXTRA_IMAGE:
    case UPLOAD_EXTRA_IMAGE:
    case EDIT_EXTRA:
      return {
        ...state,
        extra_list: state.extra_list.map(state => (state._id === action.payload._id ? action.payload : state))
      }
    case DELETE_EXTRA:
      return {
        ...state,
        extra_list: state.extra_list.filter(state => state._id !== action.payload)
      }
    default:
      return state
  }
}
