import { FCMActionState, FCMState, GET_FCM_LIST } from './types'

const initialState: FCMState = {
  fcm_list: []
}

export default function(state= initialState, action: FCMActionState): FCMState {
  switch (action.type) {
    case GET_FCM_LIST:
      return {
        ...state,
        fcm_list: action.payload
      }
    default:
      return state
  }
}
