export const GET_KITCHEN_LIST = 'GET_KITCHEN_LIST'
export const GET_KITCHEN = 'GET_KITCHEN'
export const ADD_KITCHEN = 'ADD_KITCHEN'
export const EDIT_KITCHEN = 'EDIT_KITCHEN'
export const DELETE_KITCHEN = 'DELETE_KITCHEN'

export interface Location {
  coordinates: number[];
  _id: string;
  type: string;
}

export interface KitchenType {
  range: number;
  _id: string;
  nama: string;
  location: Location;
  alamat: string;
  merchant: string;
  code: string
  __v: number;
}

interface GetKitchenListType {
  type: typeof GET_KITCHEN_LIST
  payload: KitchenType[]
}

interface GetKitchenType {
  type: typeof GET_KITCHEN
  payload: KitchenType
}

interface AddKitchenType {
  type: typeof ADD_KITCHEN
  payload: KitchenType
}

interface EditKitchenType {
  type: typeof EDIT_KITCHEN
  payload: KitchenType
}

interface DeleteKitchenType {
  type: typeof DELETE_KITCHEN
  payload: KitchenType
}

export interface KitchenState {
  kitchen_list: KitchenType[]
  kitchen: KitchenType | null
}

export type KitchenActionType =
  | GetKitchenListType
  | GetKitchenType
  | AddKitchenType
  | EditKitchenType
  | DeleteKitchenType
