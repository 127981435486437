import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom'

import PrivateRoute from './components/common/PrivateRoute'
import { Alert } from './components/layout'

import { GlobalStyles } from './styles'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'))
const Food = React.lazy(() => import('./components/views/BackOffice/Food/Food'))
const Varian = React.lazy(() => import('./components/views/BackOffice/Food/Varian/Varian'))
const Extra = React.lazy(() => import('./components/views/BackOffice/Food/Extra/Extra'))

const Login = React.lazy(() => import('./pages/Auth/Login'))
const Register = React.lazy(() => import('./pages/Auth/Register'))
const VerifySuccessPage = React.lazy(() => import('./pages/Verify/VerifySuccess'))
const RequestResetPassword = React.lazy(() => import('./pages/Auth/RequestToResetPassword'))
const ResetPassword = React.lazy(() => import('./pages/Auth/ResetPassword'))
const Area = React.lazy(() => import('./pages/Area/Area'))

const Main: React.FC = () => {
  return (
    <>
      <GlobalStyles />
      <Router>
        <Alert />
        <Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Halaman Log In" component={Login} />
            <Route exact path="/verify/:token" name="Halaman Log In" component={VerifySuccessPage} />
            <Route exact path="/reset-password" name="Halaman Reset Password" component={RequestResetPassword} />
            <Route exact path="/reset-password/:token" component={ResetPassword} />
            <Route exact path="/register" name="Halaman Register" component={Register} />
            <Route exact path="/area" name="Daftar Area" component={Area} />

            <PrivateRoute path="/" name="Beranda" component={DefaultLayout} />
            <PrivateRoute path="/food/:slug" exact name="Varian Produk Makanan" component={Food} />
            <PrivateRoute path="/food/:foodID/varian/:slug" exact name="Varian Produk Makanan" component={Varian} />
            <PrivateRoute path="/food/:foodID/extra/:slug" exact name="Ekstra Produk Makanan" component={Extra} />
          </Switch>
        </Suspense>
      </Router>
    </>
  )
}

export default withRouter( Main )
