import {
  SuperAdminState,
  SuperAdminActionType,
  GET_ROLE_LIST,
  DELETE_ROLE,
  ADD_ROLE,
  EDIT_ROLE,
  GET_STAFF_LIST,
  DELETE_STAFF,
  TOGGLE_STAFF_STATUS,
  ASSIGN_ROLE,
  UNASSIGN_ROLE,
  ADD_PERMISSION,
  REMOVE_PERMISSION
} from './types'

const initialState: SuperAdminState = {
  role_list: [],
  staff_list: []
}

export default function (state = initialState, action: SuperAdminActionType): SuperAdminState {
  switch (action.type) {
    case GET_ROLE_LIST:
      return {
        ...state,
        role_list: action.payload
      }
    case GET_STAFF_LIST:
      return {
        ...state,
        staff_list: action.payload
      }
    case ASSIGN_ROLE:
    case UNASSIGN_ROLE:
    case TOGGLE_STAFF_STATUS:
      return {
        ...state,
        staff_list: state.staff_list.map(state => (state._id === action.payload._id ? action.payload : state))
      }
    case DELETE_STAFF:
      return {
        ...state,
        staff_list: state.staff_list.filter(state => state._id !== action.payload._id)
      }
    case ADD_ROLE:
      return {
        ...state,
        role_list: [...state.role_list, action.payload]
      }
    case ADD_PERMISSION:
    case REMOVE_PERMISSION:
    case EDIT_ROLE:
      return {
        ...state,
        role_list: state.role_list.map(state => (state._id === action.payload._id ? action.payload : state))
      }
    case DELETE_ROLE:
      return {
        ...state,
        role_list: state.role_list.filter(state => state._id !== action.payload)
      }
    default:
      return state
  }
}
