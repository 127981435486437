import {
  ADD_FOOD,
  EDIT_FOOD,
  FOOD_REMOVE_PHOTO,
  FOOD_UPLOAD_PHOTO,
  FoodActionType,
  FoodState,
  GET_CUSTOMER_FOOD_LIST,
  UPDATE_CUSTOMER_FOOD_LIST,
  GET_FOOD,
  GET_FOOD_LIST,
  GET_TRASHED_FOOD_LIST,
  PUBLISH_FOOD,
  RESTORE_FOOD,
  SET_PROMO,
  SOFT_DELETE_FOOD,
  TOGGLE_FEATURED_SETTING,
  UNPUBLISH_FOOD,
  UNSET_PROMO,
  GET_NEW_FOOD_LIST,
  GET_FOOD_CATEGORY_LIST,
  FoodCategoryListType,
  GET_SEARCH_KEY_FOOD_CATEGORY_LIST,
  SearchKeyFoodCategoryListType,
  UPDATE_SEARCH_KEY_FOOD_CATEGORY_LIST,
  GET_FOOD_HISTORY_LIST
} from "./types";

const initialState: FoodState = {
  customer_food: {
    docs: [],
    totalDocs: 0,
    limit: 0,
    page: 1,
    totalPages: 1,
    pagingCounter: 1,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null
  },
  new_food_list: {
    docs: [],
    totalDocs: 0,
    limit: 0,
    page: 1,
    totalPages: 1,
    pagingCounter: 1,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null
  },
  food_list: [],
  food: null,
  food_trashed: [],
  food_categories: {},
  search_key_food_categories: {},
  food_history: []
}

export default function(state = initialState, action: any): FoodState {
  switch (action.type) {
    case GET_FOOD_LIST:
      return {
        ...state,
        food_list: action.payload
      }
    case GET_NEW_FOOD_LIST:
      return {
        ...state,
        new_food_list: action.payload
      }
    case GET_CUSTOMER_FOOD_LIST:
      return {
        ...state,
        customer_food: action.payload
      }
    case UPDATE_CUSTOMER_FOOD_LIST:
      return {
        ...state,
        customer_food: { ...state.customer_food, docs: action.payload }
      }
    case GET_TRASHED_FOOD_LIST:
      return {
        ...state,
        food_trashed: action.payload
      }
    case GET_FOOD:
      return {
        ...state,
        food: action.payload
      }
    case ADD_FOOD:
      return {
        ...state,
        food_list: [...state.food_list, action.payload]
      }
    case TOGGLE_FEATURED_SETTING:
    case SET_PROMO:
    case UNSET_PROMO:
    case FOOD_UPLOAD_PHOTO:
    case FOOD_REMOVE_PHOTO:
    case PUBLISH_FOOD:
    case UNPUBLISH_FOOD:
    case EDIT_FOOD:
      return {
        ...state,
        food_list: state.food_list.map(state => (state._id === action.payload._id ? action.payload : state))
      }
    case SOFT_DELETE_FOOD:
      return {
        ...state,
        food_list: state.food_list.filter(state => state._id !== action.payload._id),
        food_trashed: [...state.food_trashed, action.payload]
      }
    case RESTORE_FOOD:
      return {
        ...state,
        food_trashed: state.food_trashed.filter(state => state._id !== action.payload._id),
        food_list: [...state.food_list, action.payload]
      }
    case GET_FOOD_CATEGORY_LIST:
      let newFood: FoodCategoryListType = {}
      newFood[action.key] = action.payload
      return {
        ...state,
        food_categories: {
          ...state.food_categories,
          ...newFood
        }
      }
    case GET_SEARCH_KEY_FOOD_CATEGORY_LIST:
      let newSearch: SearchKeyFoodCategoryListType = {}
      newSearch[action.key] = action.payload
      return {
        ...state,
        search_key_food_categories: {
          ...state.search_key_food_categories,
          ...newSearch
        }
      }
    case UPDATE_SEARCH_KEY_FOOD_CATEGORY_LIST:
      let newUpdateKey: SearchKeyFoodCategoryListType = {}
      newUpdateKey[action.key] = action.payload
      return {
        ...state,
        search_key_food_categories: { ...state.search_key_food_categories, ...newUpdateKey }
      }
    case GET_FOOD_HISTORY_LIST:
      return {
        ...state,
        food_history: action.payload
      }
    default:
      return state
  }
}
