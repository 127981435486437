import {
  ADD_KITCHEN,
  DELETE_KITCHEN,
  EDIT_KITCHEN,
  GET_KITCHEN_LIST,
  KitchenActionType,
  KitchenState
} from "./types";

const intialState: KitchenState = {
  kitchen_list: [],
  kitchen: null
}

export default function (state = intialState, action: KitchenActionType): KitchenState {
  switch (action.type) {
    case GET_KITCHEN_LIST:
      return {
        ...state,
        kitchen_list: action.payload
      }
    case ADD_KITCHEN:
      return {
        ...state,
        kitchen_list: state.kitchen_list.concat(action.payload)
      }
    case EDIT_KITCHEN:
      return {
        ...state,
        kitchen_list: state.kitchen_list.map(state => (state._id === action.payload._id ? action.payload : state))
      }
    case DELETE_KITCHEN:
      return {
        ...state,
        kitchen_list: state.kitchen_list.filter(state => state._id !== action.payload._id)
      }
    default:
      return state
  }
}
