export const GET_BLOG_CATEGORY_LIST = 'GET_BLOG_CATEGORY_LIST'
export const GET_BLOG_CATEGORY_DETAIL = 'GET_BLOG_CATEGORY_DETAIL'
export const ADD_BLOG_CATEGORY = 'ADD_BLOG_CATEGORY'
export const EDIT_BLOG_CATEGORY = 'EDIT_BLOG_CATEGORY'
export const DELETE_BLOG_CATEGORY = 'DELETE_BLOG_CATEGORY'

export interface BlogCategory {
  deleted: boolean;
  _id: string;
  judul: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface GetBlogCategoryListType {
  type: typeof GET_BLOG_CATEGORY_LIST
  payload: BlogCategory[]
}

export interface AddBlogCategoryType {
  type: typeof ADD_BLOG_CATEGORY
  payload: BlogCategory
}

export interface EditBlogCategoryType {
  type: typeof EDIT_BLOG_CATEGORY
  payload: BlogCategory
}

export interface DeleteBlogCategoryType {
  type: typeof DELETE_BLOG_CATEGORY
  payload: BlogCategory
}

export interface BlogCategoryState {
  blog_category: BlogCategory[]
}

export type BlogCategoryActionType =
  | GetBlogCategoryListType
  | AddBlogCategoryType
  | EditBlogCategoryType
  | DeleteBlogCategoryType
