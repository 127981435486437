import firebase from "firebase"

const firebaseConfig = {
  apiKey: 'AIzaSyCKsDOuz9EMrQLqJ8NtmANz4UZ16NmIixM',
  authDomain: 'ipiring-id.firebaseapp.com',
  databaseURL: 'https://ipiring-id.firebaseio.com',
  projectId: 'ipiring-id',
  storageBucket: 'ipiring-id.appspot.com',
  messagingSenderId: '786766497543',
  appId: '1:786766497543:web:6ca3258737c338418da7a2',
  measurementId: 'G-WPQXN3MFN4'
}

export default firebase.initializeApp(firebaseConfig, "secondary");
