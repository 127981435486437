import React from 'react'
import { CModal, CModalBody, CModalFooter, CModalHeader, CButton } from '@coreui/react'

interface DialogProps {
  text: string
  show: boolean
  onClose: VoidFunction
  onSubmit: VoidFunction
}

const ConfirmationDialog: React.FC<DialogProps> = ({ text, show, onClose, onSubmit }) => {
  return (
    <CModal show={show} onClose={onClose} centered>
      <CModalHeader closeButton></CModalHeader>
      <CModalBody>
        <p>{text}</p>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" onClick={onClose}>
          Batal
        </CButton>
        <CButton color="danger" onClick={onSubmit}>
          Ya
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default React.memo(ConfirmationDialog, (prevProps, nextProps) => {
  if (prevProps.show !== nextProps.show) return false
  return true
})
