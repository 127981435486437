import { combineReducers } from "redux";
import { useSelector, TypedUseSelectorHook } from "react-redux";

import apk from "./superAdmin/APK/reducers";
import auth from "./auth/reducers";
import blog from "./backOffice/blog/reducers";
import blogCategory from "./backOffice/blogCategory/reducers";
import customerSupport from "./backOffice/chat/reducers";
import delivery from "./backOffice/delivery/reducers";
import extra from "./backOffice/food/extra/reducers";
import fcm from "./backOffice/notificationManagement/reducers"
import food from "./backOffice/food/reducers";
import foodCategory from "./backOffice/foodCategory/reducers";
import greeting from "./backOffice/greeting/reducers";
import holiday from "./backOffice/holiday/reducers"
import homeSlider from "./backOffice/homeSlider/reducers";
import kitchen from "./backOffice/kitchen/reducers";
import manualOrder from "./backOffice/order/addManualOrder/reducers";
import order from "./backOffice/order/reducers";
import stock from "./backOffice/stock/reducers";
import payment from "./backOffice/payment/reducers";
import privilege from "./backOffice/privilege/reducers";
import qr from './backOffice/qrGenerator/reducers'
import report from "./backOffice/report/reducers";
import serverArea from "./superAdmin/Server/reducers";
import support from "./support/reducers";
import superAdmin from "./superAdmin/reducers";
import superAppHomeSlider from "./superAdmin/homeSlider/reducers"
import superAppBlog from './superAdmin/blog/reducers'
import superAppBlogCategory from "./superAdmin/blogCategory/reducers"
import transfer from "./backOffice/transferApproval/reducers";
import variant from "./backOffice/food/variant/reducers";
import voucher from "./backOffice/voucher/reducers";
import whatsapp from "./googleFirebase/whatsappAPI/reducers";
import whatsappV2 from "./googleFirebase/whatsappAPIV2/reducers"
import { APK, APKState } from "./superAdmin/APK/types";
import { AuthState } from "./auth/types";
import { SuperAdminState } from "./superAdmin/types";
import { SuperAppHomeSliderState } from "./superAdmin/homeSlider/types"
import { SuperAppBlogState } from './superAdmin/blog/types'
import { SuperAppBlogCategoryState } from './superAdmin/blogCategory/types'
import { SupportState } from "./support/types";
import { BlogCategoryState } from "./backOffice/blogCategory/types";
import { BlogState } from "./backOffice/blog/types";
import { CustomerSupportState } from "./backOffice/chat/types";
import { DeliveryState } from "./backOffice/delivery/types";
import { ExtraState } from "./backOffice/food/extra/types";
import { FCMState } from './backOffice/notificationManagement/types'
import { FoodCategoryState } from "./backOffice/foodCategory/types";
import { FoodState } from "./backOffice/food/types";
import { GreetingState } from "./backOffice/greeting/types";
import { HolidayState } from './backOffice/holiday/types'
import { HomeSliderState } from "./backOffice/homeSlider/types";
import { KitchenState } from "./backOffice/kitchen/types";
import { ManualOrderState } from "./backOffice/order/addManualOrder/types";
import { OrderState } from "./backOffice/order/types";
import { PaymentState } from "./backOffice/payment/types";
import { PrivilegeState } from "./backOffice/privilege/types";
import { QRState } from './backOffice/qrGenerator/types'
import { ReportState } from "./backOffice/report/types";
import { ServerAreaState } from "./superAdmin/Server/types";
import { StockState } from "./backOffice/stock/types";
import { TransferState } from "./backOffice/transferApproval/types";
import { VariantState } from "./backOffice/food/variant/types";
import { VoucherState } from "./backOffice/voucher/types";
import { WhatsappAPIState } from "./googleFirebase/whatsappAPI/types";
import { WhatsappAPIV2State } from "./googleFirebase/whatsappAPIV2/types"

interface RootState {
  apk: APKState;
  auth: AuthState;
  serverArea: ServerAreaState;
  superAdmin: SuperAdminState;
  superAppHomeSlider: SuperAppHomeSliderState;
  superAppBlog: SuperAppBlogState;
  superAppBlogCategory: SuperAppBlogCategoryState;
  support: SupportState;
  blog: BlogState;
  blogCategory: BlogCategoryState;
  customerSupport: CustomerSupportState;
  delivery: DeliveryState;
  extra: ExtraState;
  fcm: FCMState;
  food: FoodState;
  foodCategory: FoodCategoryState;
  greeting: GreetingState;
  holiday: HolidayState;
  homeSlider: HomeSliderState;
  kitchen: KitchenState;
  manualOrder: ManualOrderState;
  order: OrderState;
  payment: PaymentState;
  privilege: PrivilegeState;
  qr: QRState;
  report: ReportState;
  stock: StockState;
  transfer: TransferState;
  voucher: VoucherState;
  variant: VariantState;
  whatsapp: WhatsappAPIState;
  whatsappV2: WhatsappAPIV2State;
}

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default combineReducers({
  apk,
  auth,
  serverArea,
  superAdmin,
  superAppHomeSlider,
  superAppBlog,
  superAppBlogCategory,
  blog,
  blogCategory,
  customerSupport,
  delivery,
  fcm,
  food,
  foodCategory,
  extra,
  greeting,
  holiday,
  homeSlider,
  kitchen,
  manualOrder,
  order,
  payment,
  privilege,
  qr,
  report,
  stock,
  support,
  transfer,
  variant,
  voucher,
  whatsapp,
  whatsappV2
});
