import {
  GET_TRANSFER_LIST,
  TransferActionType,
  TransferState
} from "./types";

const defaultList = {
  docs: [],
  totalDocs: 0,
  limit: 0,
  page: 1,
  totalPages: 1,
  pagingCounter: 1,
  hasPrevPage: false,
  hasNextPage: false,
  prevPage: null,
  nextPage: null
}

const initialState: TransferState = {
  transfer_list: defaultList
}

export default function(state = initialState, action: TransferActionType): TransferState {
  switch (action.type) {
    case GET_TRANSFER_LIST:
      return {
        ...state,
        transfer_list: action.payload
      }
    default:
      return state
  }
}
