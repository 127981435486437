import React, { ReactElement, useState } from "react";
import { CButton, CButtonGroup, CCard, CCardBody, CCol, CRow } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilPlus } from "@coreui/icons";

interface Props {
  children: ReactElement[],
  handlePaymentForm: () => void
}

const TabsWrapper: React.FC<Props> = ({children, handlePaymentForm}) => {

  const [activeTab, setActiveTab] = useState(children[0].props.id)

  const onClickTabItem = (tab: string) => {
    setActiveTab(tab)
  }

  return(
    <>
      <CRow alignVertical="center" className="my-3">
        <CCol>
          <CButtonGroup>
            {children.map((child, idx) => {
              const { id } = child.props

              type Variant = '' | 'outline' | 'ghost'

              let variant: Variant

              variant = 'outline'

              if (activeTab === id) {
                variant = ''
              }
              return (
                <CButton key={idx} color="info" variant={variant} onClick={() => onClickTabItem(id)}>
                  {id}
                </CButton>
              )
            })}
          </CButtonGroup>
        </CCol>
        <CCol className="text-right">
          <CButton color="primary" onClick={handlePaymentForm}>
            <CIcon content={cilPlus} className="mfe-2" /> Tambah Metode Payment
          </CButton>
        </CCol>
      </CRow>
      <CCard>
        <CCardBody>
          <CRow className="data-content">
            <CCol>
              {children.map(child => {
                if (child.props.id !== activeTab) return undefined
                return child.props.children
              })}
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </>
  )
}

export default TabsWrapper
