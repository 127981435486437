export const GET_FCM_LIST = 'GET_FCM_LIST'

export interface LastLogin {
  ipAddress: string;
  deviceType: string;
  timestamp: Date;
}

export interface Issuer {
  verified: boolean;
  disabled: boolean;
  deleted: boolean;
  _id: string;
  nama: string;
  hp: string;
  email: string;
  __v: number;
  last_login: LastLogin;
  createdAt: Date;
  updatedAt: Date;
  foto: string;
}

export interface fcmList {
  sent: boolean;
  target: string;
  _id: string;
  title: string;
  body: string;
  schedule: Date;
  issuer: Issuer;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
}

export interface GetFcmListType {
  type: typeof GET_FCM_LIST,
  payload: fcmList[]
}

export interface FCMState {
  fcm_list: fcmList[]
}

export type FCMActionState =
  | GetFcmListType
