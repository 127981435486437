import {
  SuperAppBlogCategoryActionType,
  SuperAppBlogCategoryState,
  ADD_SUPER_APP_BLOG_CATEGORY,
  EDIT_SUPER_APP_BLOG_CATEGORY,
  GET_SUPER_APP_BLOG_CATEGORY_LIST
} from './types'

const initialState: SuperAppBlogCategoryState = {
  super_app_blog_category: []
}

export default function (state = initialState, action: SuperAppBlogCategoryActionType): SuperAppBlogCategoryState {
  switch (action.type) {
    case GET_SUPER_APP_BLOG_CATEGORY_LIST:
      return {
        ...state,
        super_app_blog_category: action.payload
      }
    case ADD_SUPER_APP_BLOG_CATEGORY:
      return {
        ...state,
        super_app_blog_category: [...state.super_app_blog_category, action.payload]
      }
    case EDIT_SUPER_APP_BLOG_CATEGORY:
      return {
        ...state,
        super_app_blog_category: state.super_app_blog_category.map(state => (state._id === action.payload._id ? action.payload : state))
      }
    default:
      return state
  }
}
