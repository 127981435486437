export const GET_TRANSFER_LIST = 'GET_TRANSFER_LIST'

export interface Location {
  coordinates: number[];
  _id: string;
  type: string;
}

export interface Area {
  released: boolean;
  _id: string;
  nama: string;
  server: string;
  location: Location;
  analytics: string;
  timezone: string;
  __v: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface Doc {
  deleted: boolean;
  _id: string;
  order: number;
  base: number;
  code: number;
  total: number;
  area: Area;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
}

export interface TransferType {
  docs: Doc[];
  totalDocs: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: null | number,
  nextPage: null | number,
}

interface GetTransferList {
  type: typeof GET_TRANSFER_LIST
  payload: TransferType
}

export interface TransferState {
  transfer_list: TransferType
}

export type TransferActionType =
  | GetTransferList
