import {
  GET_CARD_LIST,
  GET_FIREBASE_CUSTOM_TOKEN,
  CustomerSupportActionType,
  CustomerSupportState
} from "./types";

const initialState: CustomerSupportState = {
  card_list: {},
  custom_token: ""
};

export default function(state = initialState, action: CustomerSupportActionType): CustomerSupportState {
  switch (action.type) {
    case GET_CARD_LIST:
      return {
        ...state,
        card_list: action.payload
      };
    case GET_FIREBASE_CUSTOM_TOKEN:
      return {
        ...state,
        custom_token: action.payload
      };
    default:
      return state;
  }
}
