export const REGSITER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAILED = 'REGISTER_FAILED'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_AREA_SUCCESS = 'LOGIN_AREA_SUCCESS'
export const WHOAMI = 'WHOAMI'
export const AUTH_ERROR = 'AUTH_ERROR'
export const GET_AREA_LIST = 'GET_AREA_LIST'
export const SWITCH_BRANCHES = 'SWITCH_BRANCHES'

interface Privilege {
  nama: string
  area: string
  permission: string[]
}

export interface UserLoggedIn {
  nama: string
  ip: any
  iat: number
  uid: string
  privilege: Privilege[]
}

export interface AreaType {
  _id: string
  nama: string
  server: string
  createdAt?: string
  updatedAt?: string
  _v?: number
}

interface LoginAreaType extends AreaType {
  token: string
}

export interface WhoAmIType {
  type: typeof WHOAMI
  payload: UserLoggedIn
}

export interface RegisterSuccessType {
  type: typeof REGSITER_SUCCESS
  payload: boolean
}

export interface LogoutSuccessType {
  type: typeof LOGOUT_SUCCESS
}

export interface LoginSuccessType {
  type: typeof LOGIN_SUCCESS
  payload: string
}

export interface AuthErrorType {
  type: typeof AUTH_ERROR
}

export interface AreaLoggedInType {
  type: typeof LOGIN_AREA_SUCCESS
  payload: LoginAreaType
}

export interface SwitchBranchesType {
  type: typeof SWITCH_BRANCHES
}

export interface AuthState {
  token_pusat: null | string
  isAuthPusat: boolean
  isAuthArea: boolean
  register_status: boolean
  user: null | UserLoggedIn
  current_area: null | AreaType
}

export type AuthActionType =
  | RegisterSuccessType
  | LogoutSuccessType
  | LoginSuccessType
  | AuthErrorType
  | WhoAmIType
  | AreaLoggedInType
  | SwitchBranchesType
