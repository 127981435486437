import {
  Cart,
  ManualOrderState,
  ManualOrderType,
  GET_FIREBASE_LIST,
  ADD_FIREBASE_LIST,
  GET_PREVIEW_CART,
  GET_MANUAL_ORDER_STATUS
} from "./types";

const initialState: ManualOrderState = {
  firebase_uid: null,
  preview_cart: {
    cart: [],
    _id: '',
    qty: 0,
    total: 0,
    promo: 0,
    discount: 0,
    createdAt: '',
    updatedAt: '',
    __v: 0,
    totalAfter: 0
  },
  manual_order_status: {
    message: ''
  }
}

export default function(state = initialState, action: any): ManualOrderState {
  switch (action.type) {
    case GET_FIREBASE_LIST:
    case ADD_FIREBASE_LIST:
      return {
        ...state,
        firebase_uid: action.payload
      }
    case GET_PREVIEW_CART:
      return {
        ...state,
        preview_cart: action.payload
      }
    case GET_MANUAL_ORDER_STATUS:
      return {
        ...state,
        manual_order_status: action.payload
      }
    default:
      return state
  }
}
