import {
  GET_WHATSAPP_CHAT,
  GET_WHATSAPP_STATUS,
  WhatsappAPIActionType,
  WhatsappAPIState
} from "./types";

const initialState: WhatsappAPIState = {
  whatsapp_status: {},
  whatsapp_chats: {}
};

export default function(state = initialState, action: WhatsappAPIActionType): WhatsappAPIState {
  switch (action.type) {
    case GET_WHATSAPP_CHAT:
      return {
        ...state,
        whatsapp_chats: action.payload
      };
    case GET_WHATSAPP_STATUS:
      return {
        ...state,
        whatsapp_status: action.payload
      };
    default:
      return state;
  }
}
