import {
  GET_AVAILABLE_DEVICES,
  GET_DEVICES_MAPPING,
  GET_NEW_WHATSAPP_STATUS,
  GET_SELECTED_DEVICE,
  WhatsappAPIV2ActionType,
  WhatsappAPIV2State
} from "./types";

const initialize: WhatsappAPIV2State = {
  whatsapp_devices: {},
  available_device: [],
  selected_device: '',
  whatsapp_status: {}
}

export default function(state= initialize, action: WhatsappAPIV2ActionType): WhatsappAPIV2State {
  switch (action.type) {
    case GET_DEVICES_MAPPING:
      return {
        ...state,
        whatsapp_devices: action.payload
      };
    case GET_AVAILABLE_DEVICES:
      return {
        ...state,
        available_device: action.payload
      };
    case GET_SELECTED_DEVICE:
      return {
        ...state,
        selected_device: action.payload
      };
    case GET_NEW_WHATSAPP_STATUS:
      return {
        ...state,
        whatsapp_status: action.payload
      };
    default:
      return state;
  }
}
