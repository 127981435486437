import { Variant } from '../variant/types'

export const GET_EXTRA_LIST = 'GET_EXTRA_LIST'
export const REORDER_EXTRA = 'REORDER_EXTRA'
export const ADD_EXTRA = 'ADD_EXTRA'
export const EDIT_EXTRA = 'EDIT_EXTRA'
export const DELETE_EXTRA = 'DELETE_EXTRA'
export const UPLOAD_EXTRA_IMAGE = 'UPLOAD_EXTRA_IMAGE'
export const REMOVE_EXTRA_IMAGE = 'REMOVE_EXTRA_IMAGE'

export interface Extra extends Variant {}

export interface GetListType {
  type: typeof GET_EXTRA_LIST
  payload: Extra[]
}

export interface ReorderExtraType {
  type: typeof REORDER_EXTRA
  payload: Extra[]
}

export interface AddExtraType {
  type: typeof ADD_EXTRA
  payload: Extra
}

export interface EditExtraType {
  type: typeof EDIT_EXTRA
  payload: Extra
}

export interface DeleteExtraType {
  type: typeof DELETE_EXTRA
  payload: string
}

export interface UploadExtraImageType {
  type: typeof UPLOAD_EXTRA_IMAGE
  payload: Extra
}

export interface RemoveExtraImageType {
  type: typeof REMOVE_EXTRA_IMAGE
  payload: Extra
}

export interface ExtraState {
  extra_list: Extra[]
}

export type ExtraActionType =
  | GetListType
  | ReorderExtraType
  | AddExtraType
  | EditExtraType
  | DeleteExtraType
  | UploadExtraImageType
  | RemoveExtraImageType
