import { Media } from '../types'

export const GET_VARIANT_LIST = 'GET_VARIANT_LIST'
export const REORDER_VARIANT = 'REORDER_VARIANT'
export const ADD_VARIANT = 'ADD_VARIANT'
export const EDIT_VARIANT = 'EDIT_VARIANT'
export const DELETE_VARIANT = 'DELETE_VARIANT'
export const UPLOAD_VARIANT_IMAGE = 'UPLOAD_VARIANT_IMAGE'
export const REMOVE_VARIANT_IMAGE = 'REMOVE_VARIANT_IMAGE'

export interface Variant {
  order: number
  stokDasar: number
  gambar: Media[]
  _id: string
  nama: string
  harga: number
  deskripsi: string
  slug: string
}

export interface GetListType {
  type: typeof GET_VARIANT_LIST
  payload: Variant[]
}

export interface ReorderVariantType {
  type: typeof REORDER_VARIANT
  payload: Variant[]
}

export interface AddVariantType {
  type: typeof ADD_VARIANT
  payload: Variant
}

export interface EditVariantType {
  type: typeof EDIT_VARIANT
  payload: Variant
}

export interface DeleteVariantType {
  type: typeof DELETE_VARIANT
  payload: string
}

export interface UploadVariantImageType {
  type: typeof UPLOAD_VARIANT_IMAGE
  payload: Variant
}

export interface RemoveVariantImageType {
  type: typeof REMOVE_VARIANT_IMAGE
  payload: Variant
}

export interface VariantState {
  variant_list: Variant[]
}

export type VariantActionType =
  | GetListType
  | ReorderVariantType
  | AddVariantType
  | EditVariantType
  | DeleteVariantType
  | UploadVariantImageType
  | RemoveVariantImageType
