import {
  GET_VOUCHER_LIST,
  GET_VOUCHER_TRASHED_LIST,
  GET_VOUCHER,
  ADD_VOUCHER,
  EDIT_VOUCHER,
  DELETE_VOUCHER,
  RESTORE_VOUCHER,
  ACTIVATED_VOUCHER,
  DEACTIVATED_VOUCHER,
  GET_VOUCHER_RULE,
  ADD_VOUCHER_RULE,
  EDIT_VOUCHER_RULE,
  DELETE_VOUCHER_RULE,
  RESET_VOUCHER_RULE,
  ADD_VOUCHER_RULE_FOOD,
  DELETE_VOUCHER_RULE_FOOD,
  VoucherActionType,
  VoucherState
} from './types'

const initialState: VoucherState = {
  voucher_rule_list: [],
  voucher_list: [],
  voucher_trashed: [],
  voucher: null
}

export default function(state = initialState, action: VoucherActionType): VoucherState {
  switch (action.type) {
    case GET_VOUCHER_LIST:
      return {
        ...state,
        voucher_list: action.payload
      }
    case GET_VOUCHER_TRASHED_LIST:
      return {
        ...state,
        voucher_trashed: action.payload
      }
    case GET_VOUCHER:
      return {
        ...state,
        voucher: action.payload
      }
    case ADD_VOUCHER:
      return {
        ...state,
        voucher_list: [...state.voucher_list, action.payload]
      }
    case ADD_VOUCHER_RULE_FOOD:
    case DELETE_VOUCHER_RULE_FOOD:
    case RESET_VOUCHER_RULE:
    case ACTIVATED_VOUCHER:
    case DEACTIVATED_VOUCHER:
    case EDIT_VOUCHER:
      return {
        ...state,
        voucher_list: state.voucher_list.map(state => (state._id === action.payload._id ? action.payload : state))
      }
    case DELETE_VOUCHER:
      return {
        ...state,
        voucher_list: state.voucher_list.filter(state => state._id !== action.payload._id),
        voucher_trashed: [...state.voucher_trashed, action.payload]
      }
    case RESTORE_VOUCHER:
      return {
        ...state,
        voucher_trashed: state.voucher_trashed.filter(state => state._id !== action.payload._id),
        voucher_list: [...state.voucher_list, action.payload]
      }
    case GET_VOUCHER_RULE:
      return {
        ...state,
        voucher_rule_list: action.payload
      }
    case ADD_VOUCHER_RULE:
      return {
        ...state,
        voucher_rule_list: [...state.voucher_rule_list, action.payload]
      }
    case EDIT_VOUCHER_RULE:
      return {
        ...state,
        voucher_rule_list: state.voucher_rule_list.map(state => (state._id === action.payload._id ? action.payload : state))
      }
    case DELETE_VOUCHER_RULE:
      return {
        ...state,
        voucher_rule_list: state.voucher_rule_list.filter(state => state._id !== action.payload._id)
      }
    default:
      return state
  }
}
