import {
  VariantState,
  VariantActionType,
  GET_VARIANT_LIST,
  ADD_VARIANT,
  EDIT_VARIANT,
  UPLOAD_VARIANT_IMAGE,
  REMOVE_VARIANT_IMAGE,
  DELETE_VARIANT,
  REORDER_VARIANT
} from './types'

const initialState: VariantState = {
  variant_list: []
}

export default function(state = initialState, action: VariantActionType): VariantState {
  switch (action.type) {
    case REORDER_VARIANT:
    case GET_VARIANT_LIST:
      return {
        ...state,
        variant_list: action.payload
      }
    case ADD_VARIANT:
      return {
        ...state,
        variant_list: [...state.variant_list, action.payload]
      }
    case REMOVE_VARIANT_IMAGE:
    case UPLOAD_VARIANT_IMAGE:
    case EDIT_VARIANT:
      return {
        ...state,
        variant_list: state.variant_list.map(state => (state._id === action.payload._id ? action.payload : state))
      }
    case DELETE_VARIANT:
      return {
        ...state,
        variant_list: state.variant_list.filter(state => state._id !== action.payload)
      }
    default:
      return state
  }
}
