export const GET_DEVICES_MAPPING = 'GET_DEVICES_MAPPING'
export const GET_AVAILABLE_DEVICES = 'GET_AVAILABLE_DEVICES'
export const GET_SELECTED_DEVICE = 'GET_SELECTED_DEVICE'
export const GET_NEW_WHATSAPP_STATUS = 'GET_NEW_WHATSAPP_STATUS'

interface getDevicesMapping {
  type: typeof GET_DEVICES_MAPPING
  payload: any
}
interface getAvailableDevices {
  type: typeof GET_AVAILABLE_DEVICES
  payload: any
}
interface getSelectedDevice {
  type: typeof GET_SELECTED_DEVICE
  payload: any
}
interface getNewDeviceStatus {
  type: typeof GET_NEW_WHATSAPP_STATUS
  payload: any
}

export interface WhatsappAPIV2State {
  selected_device: any
  available_device: any
  whatsapp_devices: any
  whatsapp_status: any
}

export type WhatsappAPIV2ActionType =
  | getSelectedDevice
  | getAvailableDevices
  | getDevicesMapping
  | getNewDeviceStatus
