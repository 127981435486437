export const GET_PRIVILEGE_LIST = 'GET_PRIVILEGE_LIST'
export const AREA_PRIVILEGE_SET = 'AREA_PRIVILEGE_SET'
export const AREA_LIST = 'AREA_LIST'
export const ROLE_LIST = 'ROLE_LIST'

export interface LastLogin {
  ipAddress: string;
  deviceType: string;
  timestamp: Date;
}

export interface Staff {
  verified: boolean;
  disabled: boolean;
  deleted: boolean;
  _id: string;
  nama: string;
  hp: string;
  email: string;
  __v: number;
  last_login: LastLogin;
  createdAt: Date;
  updatedAt: Date;
}

export interface Permission {
  _id: string;
  name: string;
}

export interface Role {
  _id: string;
  nama: string;
  jobdesk: string;
  permission: Permission[];
  __v: number;
  updatedAt: Date;
  createdAt: Date;
}

export interface Location {
  coordinates: number[];
  _id: string;
  type: string;
}

export interface Area {
  _id: string;
  nama: string;
  server: string;
  location: Location;
  __v: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface Privilege {
  _id: string;
  staff: Staff;
  role: Role;
  area: Area;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
}

export interface PrivilegeState {
  area: string,
  privileges: Privilege[],
  filtered : Privilege[],
  arealist : Area[],
  rolelist : Role[]
}
export interface GetPrivilegeListType {
  type: typeof GET_PRIVILEGE_LIST
  payload: Privilege[]
}
export interface AreaPrivilegeSetType {
  type: typeof AREA_PRIVILEGE_SET
  payload: string
}
export interface AreaListType {
  type: typeof AREA_LIST
  payload: Area[]
}
export interface RoleListType {
  type: typeof ROLE_LIST
  payload: Role[]
}
export type PrivilegeActionType =
  | GetPrivilegeListType
  | AreaPrivilegeSetType
  | AreaListType
  | RoleListType
