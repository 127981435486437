import { Dispatch } from "redux";
import "firebase/database";
import firebaseWhatsapp from "../../../utils/firebaseWhatsapp";
import { GET_AVAILABLE_DEVICES, GET_DEVICES_MAPPING, GET_NEW_WHATSAPP_STATUS, GET_SELECTED_DEVICE } from "./types";

export const getDevicesMapping = () => (dispatch: Dispatch<any>) => {
  const statusRef = firebaseWhatsapp.database().ref("whatsapp-instances/mapping");
  statusRef.once("value", (snapshot) => {
    if (snapshot.exists()) {
      dispatch({
        type: GET_DEVICES_MAPPING,
        payload: snapshot.val()
      });
    }
  });

  const devicesRef = firebaseWhatsapp.database().ref(`whatsapp-instances/devices`);
  devicesRef.once("value", (snapshot) => {
    if (snapshot.exists()) {
      dispatch({
        type: GET_AVAILABLE_DEVICES,
        payload: Object.keys(snapshot.val())
      });
    }
  });
};

export const getSelectedDevice = (areaName: string) => (dispatch: Dispatch<any>) => {
  const statusRef = firebaseWhatsapp.database().ref("whatsapp-instances/mapping");
  statusRef.once("value", (snapshot) => {
    if (snapshot.exists()) {
      const data = snapshot.val();
      if (data.hasOwnProperty(`${areaName}`)) {
        dispatch({
          type: GET_SELECTED_DEVICE,
          payload: data[areaName]
        });
        dispatch(getNewWhatsappStatus(data[areaName]));
      }
    }
  });
};

export const getNewWhatsappStatus = (device: string) => (dispatch: Dispatch<any>) => {
  const statusRef = firebaseWhatsapp.database().ref(`whatsapp-instances/devices/${device}/status`);
  statusRef.on("value", (snapshot) => {
    if (snapshot.exists()) {
      dispatch({
        type: GET_NEW_WHATSAPP_STATUS,
        payload: snapshot.val()
      });
    }
  });
};
