export const GET_SERVER_AREA_LIST = 'GET_SERVER_AREA_LIST'
export const GET_SERVER_AREA_MODULE_LIST = 'GET_SERVER_AREA_MODULE_LIST'
export const UPDATE_SERVER_AREA_LIST = 'UPDATE_SERVER_AREA_LIST'
export const GET_SERVER_VERSION = 'GET_SERVER_VERSION'
export const EDIT_SERVER_AREA = 'EDIT_SERVER_AREA'

export interface Location {
  coordinates: number[];
  _id: string;
  type: string;
}

export interface Module {
  customer: string;
  icon: string;
  isComingsoon: boolean;
  office: string;
  name: string;
  status: boolean;
}

export interface App {
  'i-food': Module;
  'i-sayur': Module;
}

export interface ServerType {
  released: boolean;
  _id: string;
  nama: string;
  server: string;
  location: Location;
  analytics: string;
  timezone: string;
  version: number;
  __v: number;
  createdAt: string;
  updatedAt: string;
  isSelected: boolean;
  apps: App
}

export interface GetServerAreaListType {
  type: typeof GET_SERVER_AREA_LIST
  payload: ServerType[]
}

export interface GetServerAreaModuleListType {
  type: typeof GET_SERVER_AREA_MODULE_LIST
  payload: ServerType[]
}

export interface UpdateServerAreaListType {
  type: typeof UPDATE_SERVER_AREA_LIST
  payload: ServerType[]
}

export interface GetServerVersionType {
  type: typeof GET_SERVER_VERSION
  payload: string[]
}

export interface EditServerAreaType {
  type: typeof EDIT_SERVER_AREA
  payload: ServerType
}

export interface ServerAreaState {
  area_module: ServerType[]
  server_area: ServerType[]
  server_version: string[]
}

export type ServerAreaActionType =
  | EditServerAreaType
  | GetServerAreaListType
  | GetServerAreaModuleListType
  | GetServerVersionType
  | UpdateServerAreaListType
