import {
  SuperAppHomeSliderActionType,
  SuperAppHomeSliderState,
  ADD_SUPER_APP_HOME_SLIDER,
  DELETE_SUPER_APP_HOME_SLIDER,
  EDIT_SUPER_APP_HOME_SLIDER,
  GET_SUPER_APP_HOME_SLIDER,
  GET_SUPER_APP_HOME_SLIDER_LIST,
  GET_SUPER_APP_TRASHED_HOME_SLIDER_LIST,
  RESTORE_SUPER_APP_HOME_SLIDER
} from './types'

const initialState: SuperAppHomeSliderState = {
  super_app_home_slider_list: [],
  trashed_super_app_home_slider_list: []
}

export default function (state = initialState, action: SuperAppHomeSliderActionType): SuperAppHomeSliderState {
  switch (action.type) {
    case GET_SUPER_APP_HOME_SLIDER_LIST:
      return {
        ...state,
        super_app_home_slider_list: action.payload
      }
    case DELETE_SUPER_APP_HOME_SLIDER:
      return {
        ...state,
        super_app_home_slider_list: state.super_app_home_slider_list.filter(homeslider => homeslider._id !== action.payload._id)
      }
    case ADD_SUPER_APP_HOME_SLIDER:
      return {
        ...state,
        super_app_home_slider_list: state.super_app_home_slider_list.concat(action.payload)
      }
    case EDIT_SUPER_APP_HOME_SLIDER:
      return {
        ...state,
        super_app_home_slider_list: state.super_app_home_slider_list.map(homeslider => homeslider._id === action.payload._id ? action.payload : homeslider)
      }
    case GET_SUPER_APP_TRASHED_HOME_SLIDER_LIST:
      return {
        ...state,
        trashed_super_app_home_slider_list: action.payload
      }
    case RESTORE_SUPER_APP_HOME_SLIDER:
      return {
        ...state,
        trashed_super_app_home_slider_list: state.trashed_super_app_home_slider_list.filter(state => state._id !== action.payload._id),
        super_app_home_slider_list: [...state.super_app_home_slider_list, action.payload]
      }
    default:
      return state
  }
}
