import { Food } from "../../food/types";
import { Variant } from "../../food/variant/types";
import { Extra } from "../../food/extra/types";

export const GET_FIREBASE_LIST = 'GET_FIREBASE_LIST'
export const ADD_FIREBASE_LIST = 'ADD_FIREBASE_LIST'
export const GET_PREVIEW_CART = 'GET_PREVIEW_CART'
export const GET_MANUAL_ORDER_STATUS = 'GET_MANUAL_ORDER_STATUS'

export interface Metadata {
  lastSignInTime: string;
  creationTime: string;
}

export interface ProviderData {
  uid: string;
  displayName: string;
  email: string;
  photoURL: string;
  providerId: string;
  phoneNumber: string;
}

export interface Firebase {
  uid: string;
  email: string;
  emailVerified: boolean;
  displayName: string;
  photoURL: string;
  phoneNumber: string;
  disabled: boolean;
  metadata: Metadata;
  tokensValidAfterTime: string;
  providerData: ProviderData[];
}

export interface Cart {
  extra: Extra[];
  _id: string;
  customer: string;
  food: Food;
  varian: Variant[];
  total: number;
  qty: number;
  discount: number;
  promo: number;
  price: number;
  catatan?: any;
}

export interface PreviewCart {
  cart: Cart[];
  _id: string;
  qty: number;
  total: number;
  promo: number;
  discount: number;
  createdAt: Date | string;
  updatedAt: Date | string;
  __v: number;
  totalAfter: number;
}

export interface ManualOrderStatus {
  message: string
}

export interface GetFirebaseList {
  type: typeof GET_FIREBASE_LIST,
  payload: Firebase
}

export interface AddFirebaseList {
  type: typeof GET_FIREBASE_LIST,
  payload: Firebase
}

export interface GetPreviewCart {
  type: typeof GET_PREVIEW_CART,
  payload: PreviewCart
}

export interface GetManualOrderStatus {
  type: typeof GET_MANUAL_ORDER_STATUS,
  payload: ManualOrderStatus
}

export interface ManualOrderState {
  firebase_uid: Firebase | any
  preview_cart: PreviewCart
  manual_order_status: ManualOrderStatus
}

export type ManualOrderType =
  | GetFirebaseList
  | AddFirebaseList
  | GetPreviewCart
  | GetManualOrderStatus
