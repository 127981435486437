import { Food } from '../food/types'
import { Drivers } from '../delivery/types'

export const GET_ORDER_LIST = 'GET_ORDER_LIST'
export const GET_ORDER = 'GET_ORDER'
export const EDIT_STATUS_ORDER = 'EDIT_STATUS_ORDER'
export const CANCEL_ORDER = 'CANCEL_ORDER'
export const REFUND = 'REFUND'
export const GET_ORDER_CATEGORY_LIST = 'GET_ORDER_CATEGORY_LIST'
export const GET_SEARCH_KEY_ORDER_CATEGORY_LIST = 'GET_SEARCH_KEY_ORDER_CATEGORY_LIST'
export const GET_AVAILABLE_DRIVER_LIST = 'GET_AVAILABLE_DRIVER_LIST'

export interface Item {
  varian: string
  extra: string[]
  deleted: boolean,
  _id: string,
  total: number,
  qty: number,
  discount: number,
  promo: number,
  price: number,
  catatan: string
  shipping: string
  createdAt: string
  updatedAt: string
  __v: number
  food: Food,
  voucher : Voucher
}

interface DeliveryAddress {
  instansi: string,
  alamat: string,
  hp: string,
  email: string
}

interface BillingAddress extends DeliveryAddress {
  nama: string
}
interface Rule {
  food: any[];
  _id: string;
  type: string;
  value: number;
}

export interface Voucher {
  isPercentage: boolean;
  isGlobal: boolean;
  isActive: boolean;
  isException: boolean;
  isPreorder: boolean;
  items: any[];
  withPromo: boolean;
  deleted: boolean;
  _id: string;
  nama: string;
  deskripsi: string;
  value: number;
  maxDiscount: number;
  startAt: Date;
  endAt: Date;
  rules: Rule[];
  createdAt: Date;
  updatedAt: Date;
  __v: number;
}

export interface Detail {
  name: string;
  stakeholder: string;
  number: string;
  icon: string;
}

export interface PaymentMethod {
  enabled: boolean;
  deleted: boolean;
  _id: string;
  code: string;
  description: string;
  name: string;
  order: number;
  detail: Detail[];
  __v: number;
}

export interface Payment {
  enabled: boolean;
  deleted: boolean;
  _id: string;
  code: string;
  description: string;
  name: string;
  order: number;
  detail: Detail[];
  __v: number;
}

export interface Device {
  browser: string;
  isMobile: boolean
  platform: string
}

export interface History {
  action: string,
  value: string,
  issuer: string,
  time: string
}

export interface Customer {
  id: string,
  isNewCustomer: boolean,
  orderSukses: number
}

export interface Location {
  coordinates: number[];
  _id: string;
  type: string;
}

export interface Kitchen {
  range: number;
  _id: string;
  nama: string;
  location: Location;
  alamat: string;
  merchant: string;
  code: string;
  __v: number;
}

export interface DocsType {
  items: Item[]
  qty: number,
  promo: number,
  discount: number,
  price: number,
  status: string,
  isPaid: boolean,
  isDelivered: boolean,
  isCanceled: boolean,
  isProcessed: boolean,
  isOtw: boolean,
  isRefunded: boolean,
  isPreorder: boolean,
  isTesting: boolean,
  paidAt: null | string,
  deliveryAt: string,
  deliveredAt: null | string,
  deliveryAddress: DeliveryAddress,
  device: Device,
  billingAddress: BillingAddress,
  history: History[],
  distance: number,
  shipping_date: string,
  shipping_time: string,
  deleted: boolean,
  _id: string,
  customer: Customer,
  payment: Payment,
  payment_method: PaymentMethod,
  deliveryLocation: {
    coordinates: number[

    ],
    _id: string,
    type: string
  },
  createdAt: string,
  updatedAt: string,
  __v: number,
  voucher: Voucher | null
  kitchen: Kitchen
}

export interface OrderType {
  docs: DocsType[],
  totalDocs: number,
  limit: number,
  totalPages: number,
  page: number,
  pagingCounter: number,
  hasPrevPage: boolean,
  hasNextPage: boolean,
  prevPage: null | number,
  nextPage: null | number,
}

export interface SearchKeyType {
  isSearching: boolean,
  searchKey: string,
  searchDate: string,
  preorderOnly: number
}

interface GetOrderListType {
  type: typeof GET_ORDER_LIST
  payload: OrderType
}

interface GetOrderType {
  type: typeof GET_ORDER
  payload: DocsType
}

interface EditStatusOrderType {
  type: typeof EDIT_STATUS_ORDER
  payload: OrderType
}

interface CancelOrderType {
  type: typeof CANCEL_ORDER
  payload: DocsType
}

interface RefundOrderType {
  type: typeof REFUND
  payload: DocsType
}

interface GetOrderCategoryListType {
  type: typeof GET_ORDER_CATEGORY_LIST
  key : string
  payload: OrderType
}

export interface OrderCategoriesListType {
  [key: string]: OrderType
}

interface GetSearchKeyOrderCategoryListType {
  type: typeof GET_SEARCH_KEY_ORDER_CATEGORY_LIST
  key : string
  payload: SearchKeyType
}

export interface SearchKeyOrderCategoryListType {
  [key: string]: SearchKeyType
}

export interface GetAvailableDriverListType {
  type: typeof GET_AVAILABLE_DRIVER_LIST
  payload: Drivers[]
}

export interface OrderState {
  order_list: OrderType
  order: DocsType | null
  order_categories : OrderCategoriesListType
  search_key_order_category: SearchKeyOrderCategoryListType
  driver_list: Drivers[]
}

export type OrderActionType =
  | GetOrderListType
  | GetOrderType
  | EditStatusOrderType
  | CancelOrderType
  | RefundOrderType
  | GetOrderCategoryListType
  | GetSearchKeyOrderCategoryListType
  | GetAvailableDriverListType
