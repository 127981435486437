export const GET_CARD_LIST = "GET_CARD_LIST";
export const GET_FIREBASE_CUSTOM_TOKEN = "GET_FIREBASE_CUSTOM_TOKEN";

export interface ChatID {
  [chatID: string]: {
    content: string;
    from: string;
    isCustomer: boolean;
    timestamp: Date;
  }
}

export interface Chats {
  chats: ChatID
}

export interface CardList {
  [userID: string]: {
    userName: string
    chats: Chats,
    status: boolean;
    title: string;
  }
}

interface GetCardListType {
  type: typeof GET_CARD_LIST
  payload: CardList
}

interface GetFirebaseCustomToken {
  type: typeof GET_FIREBASE_CUSTOM_TOKEN
  payload: ""
}

export interface CustomerSupportState {
  card_list: CardList
  custom_token: ""
}

export type CustomerSupportActionType =
  | GetCardListType
  | GetFirebaseCustomToken
