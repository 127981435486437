import {
  GET_TRASHED_LIST,
  GET_FOOD_CATEGORY_LIST,
  GET_FOOD_CATEGORY_DETAIL,
  ADD_FOOD_CATEGORY,
  EDIT_FOOD_CATEGORY,
  SOFT_DELETE_FOOD_CTG,
  HARD_DELETE_FOOD_CTG,
  FoodCategoryActionType,
  FoodCategoryState,
  RESTORE_FOOD_CTG
} from './types'

const initialState: FoodCategoryState = {
  food_ctg: [],
  selected_food_ctg: null,
  food_ctg_trashed: []
}

export default function(state = initialState, action: FoodCategoryActionType): FoodCategoryState {
  switch (action.type) {
    case GET_FOOD_CATEGORY_LIST:
      return {
        ...state,
        food_ctg: action.payload
      }
    case GET_TRASHED_LIST:
      return {
        ...state,
        food_ctg_trashed: action.payload
      }
    case GET_FOOD_CATEGORY_DETAIL:
      return {
        ...state,
        selected_food_ctg: action.payload
      }
    case ADD_FOOD_CATEGORY:
      return {
        ...state,
        food_ctg: [...state.food_ctg, action.payload]
      }
    case EDIT_FOOD_CATEGORY:
      return {
        ...state,
        food_ctg: state.food_ctg.map(state => (state._id === action.payload._id ? action.payload : state))
      }
    case SOFT_DELETE_FOOD_CTG:
      return {
        ...state,
        food_ctg: state.food_ctg.filter(state => state._id !== action.payload._id),
        food_ctg_trashed: [...state.food_ctg_trashed, action.payload]
      }
    case RESTORE_FOOD_CTG:
      return {
        ...state,
        food_ctg_trashed: state.food_ctg_trashed.filter(state => state._id !== action.payload._id),
        food_ctg: [...state.food_ctg, action.payload]
      }
    default:
      return state
  }
}
