import {
  AREA_LIST,
  AREA_PRIVILEGE_SET,
  GET_PRIVILEGE_LIST,
  PrivilegeActionType,
  PrivilegeState,
  ROLE_LIST
} from "./types";

const initialState: PrivilegeState = {
  area : 'Yogyakarta',
  privileges: [],
  filtered : [],
  arealist : [],
  rolelist : []
}

export default function(state = initialState, action: PrivilegeActionType): PrivilegeState {
  switch (action.type) {
    case GET_PRIVILEGE_LIST:
      return {
        ...state,
        privileges: action.payload,
        filtered: action.payload.filter(i => i.area.nama === state.area && i.staff)
      }
    case AREA_PRIVILEGE_SET:
      return {
        ...state,
        area: action.payload,
        filtered: state.privileges.filter(i => i.area.nama === action.payload && i.staff)
      }
    case AREA_LIST:
      return {
        ...state,
        arealist: action.payload
      }
    case ROLE_LIST:
      return {
        ...state,
        rolelist: action.payload
      }
    default:
      return state
  }
}
