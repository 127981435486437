import {
  ADD_BLOG_CATEGORY,
  BlogCategoryActionType,
  BlogCategoryState,
  EDIT_BLOG_CATEGORY,
  GET_BLOG_CATEGORY_LIST
} from "./types";

const initialState: BlogCategoryState = {
  blog_category: []
}

export default function(state= initialState, action: BlogCategoryActionType): BlogCategoryState {
  switch (action.type) {
    case GET_BLOG_CATEGORY_LIST:
      return {
        ...state,
        blog_category: action.payload
      }
    case ADD_BLOG_CATEGORY:
      return {
        ...state,
        blog_category: [...state.blog_category, action.payload]
      }
    case EDIT_BLOG_CATEGORY:
      return {
        ...state,
        blog_category: state.blog_category.map(state => (state._id === action.payload._id ? action.payload : state))
      }
    default:
      return state
  }
}
