import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cibAndroid, cibAndroidAlt, cibMicroBlog, cibWhatsapp, cilAudio, cilBeachAccess,
  cilChatBubble,
  cilClock, cilClone, cilCloud, cilColorBorder, cilCreditCard, cilDevices,
  cilDinner, cilEnvelopeClosed,
  cilFastfood,
  cilFile, cilHappy,
  cilHome,
  cilLibrary, cilList,
  cilListRich,
  cilLockUnlocked,
  cilPeople, cilQrCode, cilSettings, cilSitemap,
  cilSpeedometer,
  cilStorage,
  cilTags,
  cilWalk, cilWallet
} from '@coreui/icons'
import { UserLoggedIn } from '../redux/auth/types'
import { useTypedSelector } from "../redux/rootReducer";

export interface NavMenuType {
  _tag: string
  name: string
  to: string
  icon: JSX.Element
}

export const menuList = [
  'Server',
  'Super App Configuration',
  'Staff',
  'Peran',
  'Privilege',
  'Customer Support',
  'Whatsapp QR',
  'Whatsapp V2',
  'Kategori Produk',
  'Produk',
  'Stock Produk',
  'Kategori Blog',
  'Blog',
  'Payment',
  'Voucher',
  'Greeting',
  'Notification Management',
  'Order',
  'Home Slider',
  'QR Generator',
  'Delivery',
  'Holiday',
  'Kitchen',
  'Report',
  'Download APK',
]

const setMenu = (name: string) => {
  switch (name) {
    case "Server":
      return {
        _tag: 'CSidebarNavDropdown',
        name: 'Super Admin',
        icon: <CIcon content={cilHappy} customClasses="c-sidebar-nav-icon" />,
        _children: [
          {
            _tag: 'CSidebarNavItem',
            name: 'Server',
            to: '/server',
            icon: <CIcon content={cilCloud} customClasses="c-sidebar-nav-icon" />
          },
          {
            _tag: 'CSidebarNavItem',
            name: 'Upload APK',
            to: '/upload-apk',
            icon: <CIcon content={cibAndroid} customClasses="c-sidebar-nav-icon" />
          }
        ]
      }
    case "Super App Configuration":
      return {
        _tag: 'CSidebarNavDropdown',
        name: 'Super App Configuration',
        icon: <CIcon content={cilSettings} customClasses="c-sidebar-nav-icon" />,
        _children: [
          {
            _tag: 'CSidebarNavItem',
            name: 'Module Config',
            to: '/super-app-module-config',
            icon: <CIcon content={cilSitemap} customClasses="c-sidebar-nav-icon" />
          },
          {
            _tag: 'CSidebarNavItem',
            name: 'Home Slider',
            to: '/super-app-home-slider',
            icon: <CIcon content={cilHome} customClasses="c-sidebar-nav-icon" />
          },
          /*{
            _tag: 'CSidebarNavItem',
            name: 'Kategori Blog',
            to: '/super-app-kategori-blog',
            icon: <CIcon content={cilClone} customClasses="c-sidebar-nav-icon" />
          },*/
          {
            _tag: 'CSidebarNavItem',
            name: 'Blog',
            to: '/super-app-blog',
            icon: <CIcon content={cilColorBorder} customClasses="c-sidebar-nav-icon" />
          }
        ]
      }
    case "Staff":
      return {
        _tag: 'CSidebarNavItem',
        name: 'Staff',
        to: '/staff',
        icon: <CIcon content={cilPeople} customClasses="c-sidebar-nav-icon" />
      }
    case "Peran":
      return {
        _tag: 'CSidebarNavItem',
        name: 'Peran',
        to: '/role',
        icon: <CIcon content={cilWalk} customClasses="c-sidebar-nav-icon" />
      }
    case "Privilege":
      return {
        _tag: 'CSidebarNavItem',
        name: 'Privilege',
        to: '/privilege',
        icon: <CIcon content={cilLockUnlocked} customClasses="c-sidebar-nav-icon" />
      }
    case "Customer Support":
      return {
        _tag: 'CSidebarNavItem',
        name: 'Customer Support',
        to: '/customer-support',
        icon: <CIcon content={cilEnvelopeClosed} customClasses="c-sidebar-nav-icon" />
      }
    case "Whatsapp QR":
      return {
        _tag: 'CSidebarNavDropdown',
        name: 'Whatsapp',
        icon: <CIcon content={cibWhatsapp} customClasses="c-sidebar-nav-icon" />,
        _children: [
          {
            _tag: 'CSidebarNavItem',
            name: 'Whatsapp QR',
            to: '/whatsapp-qr',
            icon: <CIcon content={cilQrCode} customClasses="c-sidebar-nav-icon" />
          },
          {
            _tag: 'CSidebarNavItem',
            name: 'Whatsapp Chat Log',
            to: '/whatsapp-chat-log',
            icon: <CIcon content={cibMicroBlog} customClasses="c-sidebar-nav-icon" />
          }
        ]
      }
    case "Whatsapp V2":
      return {
        _tag: 'CSidebarNavDropdown',
        name: 'Whatsapp V2',
        icon: <CIcon content={cibWhatsapp} customClasses="c-sidebar-nav-icon" />,
        _children: [
          {
            _tag: 'CSidebarNavItem',
            name: 'Whatsapp QR V2',
            to: '/whatsapp-qr-v2',
            icon: <CIcon content={cilQrCode} customClasses="c-sidebar-nav-icon" />
          },
          {
            _tag: 'CSidebarNavItem',
            name: 'Whatsapp Devices V2',
            to: '/whatsapp-devices-v2',
            icon: <CIcon content={cilDevices} customClasses="c-sidebar-nav-icon" />
          }
        ]
      }
    case "Kategori Produk":
      return {
        _tag: 'CSidebarNavItem',
        name: 'Kategori Produk',
        to: '/food-category',
        icon: <CIcon content={cilLibrary} customClasses="c-sidebar-nav-icon" />
      }
    case "Produk":
      return {
        _tag: 'CSidebarNavItem',
        name: 'Produk',
        to: '/food',
        icon: <CIcon content={cilFastfood} customClasses="c-sidebar-nav-icon" />
      }
    case "Stock Produk":
      return {
        _tag: 'CSidebarNavItem',
        name: 'Stock Produk',
        to: '/stock',
        icon: <CIcon content={cilStorage} customClasses="c-sidebar-nav-icon" />
      }
    case "Kategori Blog":
      return {
        _tag: 'CSidebarNavItem',
        name: 'Kategori Blog',
        to: '/blog-category',
        icon: <CIcon content={cilClone} customClasses="c-sidebar-nav-icon" />
      }
    case "Blog":
      return {
        _tag: 'CSidebarNavItem',
        name: 'Blog',
        to: '/blog',
        icon: <CIcon content={cilColorBorder} customClasses="c-sidebar-nav-icon" />
      }
    case "Payment":
      return {
        _tag: 'CSidebarNavItem',
        name: 'Payment',
        to: '/payment',
        icon: <CIcon content={cilWallet} customClasses="c-sidebar-nav-icon" />
      }
    case "Voucher":
      return {
        _tag: 'CSidebarNavItem',
        name: 'Voucher',
        to: '/voucher',
        icon: <CIcon content={cilTags} customClasses="c-sidebar-nav-icon" />
      }
    case "Greeting":
      return {
        _tag: 'CSidebarNavItem',
        name: 'Greeting',
        to: '/greeting',
        icon: <CIcon content={cilChatBubble} customClasses="c-sidebar-nav-icon" />
      }
    case "Notification Management":
      return {
        _tag: 'CSidebarNavItem',
        name: 'Notification Management',
        to: '/notification-management',
        icon: <CIcon content={cilAudio} customClasses="c-sidebar-nav-icon" />
      }
    case "Order":
      return {
        _tag: 'CSidebarNavDropdown',
        name: 'Order',
        icon: <CIcon content={cilListRich} customClasses="c-sidebar-nav-icon" />,
        _children: [
          {
            _tag: 'CSidebarNavItem',
            name: 'Order List',
            to: '/order',
            icon: <CIcon content={cilList} customClasses="c-sidebar-nav-icon" />
          },
          {
            _tag: 'CSidebarNavItem',
            name: 'Transaction List',
            to: '/transaction',
            icon: <CIcon content={cilCreditCard} customClasses="c-sidebar-nav-icon" />
          }
        ]
      }
    case "Home Slider":
      return {
        _tag: 'CSidebarNavItem',
        name: 'Home Slider',
        to: '/homeslider',
        icon: <CIcon content={cilHome} customClasses="c-sidebar-nav-icon" />
      }
    case "QR Generator":
      return {
        _tag: 'CSidebarNavItem',
        name: 'QR Generator',
        to: '/qr-generator',
        icon: <CIcon content={cilQrCode} customClasses="c-sidebar-nav-icon" />
      }
    case "Delivery":
      return {
        _tag: 'CSidebarNavItem',
        name: 'Delivery',
        to: '/delivery',
        icon: <CIcon content={cilClock} customClasses="c-sidebar-nav-icon" />
      }
    case "Holiday":
      return {
        _tag: 'CSidebarNavItem',
        name: 'Holiday',
        to: '/holiday',
        icon: <CIcon content={cilBeachAccess} customClasses="c-sidebar-nav-icon" />
      }
    case "Kitchen":
      return {
        _tag: 'CSidebarNavItem',
        name: 'Kitchen',
        to: '/kitchen',
        icon: <CIcon content={cilDinner} customClasses="c-sidebar-nav-icon" />
      }
    case "Report":
      return {
        _tag: 'CSidebarNavItem',
        name: 'Report',
        to: '/report',
        icon: <CIcon content={cilFile} customClasses="c-sidebar-nav-icon" />
      }
    case "Download APK":
      return {
        _tag: 'CSidebarNavItem',
        name: 'Download APK',
        to: '/download-apk',
        icon: <CIcon content={cibAndroidAlt} customClasses="c-sidebar-nav-icon" />
      }
    default: break

  }
}

const useNavigation = (user: UserLoggedIn | null) => {

  const currentArea = useTypedSelector(state => state.auth.current_area)

  let initialNav: NavMenuType[] | any = [
    {
      _tag: 'CSidebarNavItem',
      name: 'Dashboard',
      to: '/dashboard',
      icon: <CIcon content={cilSpeedometer} customClasses="c-sidebar-nav-icon" />
    },
    {
      _tag: 'CSidebarNavDivider'
    },
    {
      _tag: 'CSidebarNavTitle',
      _children: ['Manajemen Konten']
    },
  ]

  if (user) {
    let navTmp = initialNav

    user.privilege.forEach(item => {
      const { permission } = item
      if ( currentArea !== null && item.area === currentArea.nama) {
        permission.forEach(menu => {
          if (!navTmp.some((nav: any) => nav.name === menu)) {
            const newMenu = setMenu(menu);
            if (Array.isArray(newMenu)) {
              navTmp = [...navTmp, ...newMenu].filter((item: any) => item !== undefined);
            } else {
              navTmp = [...navTmp, newMenu].filter((item: any) => item !== undefined);
            }
          }
        });
      }
    })

    initialNav = navTmp.filter((item: any) => item !== undefined)
  }

  return [initialNav]
}

export default useNavigation
