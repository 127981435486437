export const ADD_SUPER_APP_BLOG_CATEGORY = 'ADD_SUPER_APP_BLOG_CATEGORY'
export const DELETE_SUPER_APP_BLOG_CATEGORY = 'DELETE_SUPER_APP_BLOG_CATEGORY'
export const EDIT_SUPER_APP_BLOG_CATEGORY = 'EDIT_SUPER_APP_BLOG_CATEGORY'
export const GET_SUPER_APP_BLOG_CATEGORY_LIST = 'GET_SUPER_APP_BLOG_CATEGORY_LIST'

export interface BlogCategoryType {
  subcategories: any[];
  active: boolean;
  _id: string;
  nama: string;
  icon: string;
  deskripsi: string;
  slug: string;
  __v: number;
}

export interface GetSuperAppBlogCategoryListType {
  type: typeof GET_SUPER_APP_BLOG_CATEGORY_LIST
  payload: BlogCategoryType[]
}

export interface AddSuperAppBlogCategoryType {
  type: typeof ADD_SUPER_APP_BLOG_CATEGORY
  payload: BlogCategoryType
}

export interface EditSuperAppBlogCategoryType {
  type: typeof EDIT_SUPER_APP_BLOG_CATEGORY
  payload: BlogCategoryType
}

export interface DeleteSuperAppBlogCategoryType {
  type: typeof DELETE_SUPER_APP_BLOG_CATEGORY
  payload: BlogCategoryType
}

export interface SuperAppBlogCategoryState {
  super_app_blog_category: BlogCategoryType[]
}

export type SuperAppBlogCategoryActionType =
  | GetSuperAppBlogCategoryListType
  | AddSuperAppBlogCategoryType
  | EditSuperAppBlogCategoryType
  | DeleteSuperAppBlogCategoryType
