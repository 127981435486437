import {
  EDIT_SERVER_AREA,
  GET_SERVER_AREA_LIST,
  GET_SERVER_AREA_MODULE_LIST,
  GET_SERVER_VERSION,
  ServerAreaActionType,
  ServerAreaState,
  UPDATE_SERVER_AREA_LIST
} from './types'

const initialState: ServerAreaState = {
  area_module: [],
  server_area: [],
  server_version: []
}

export default function (state= initialState, action: ServerAreaActionType): ServerAreaState {
  switch (action.type) {
    case GET_SERVER_AREA_LIST:
      return {
        ...state,
        server_area: action.payload
      }
    case GET_SERVER_AREA_MODULE_LIST:
      return {
        ...state,
        area_module: action.payload
      }
    case UPDATE_SERVER_AREA_LIST:
      return {
        ...state,
        server_area: action.payload
      }
    case GET_SERVER_VERSION:
      return {
        ...state,
        server_version: action.payload
      }
    case EDIT_SERVER_AREA:
      return {
        ...state,
        server_area: state.server_area.map(state => (state._id === action.payload._id ? action.payload : state))
      }
    default:
      return state
  }
}
