import { Dispatch } from 'redux'
import { v4 as uuid } from 'uuid'

import {
  Error,
  ReturnErrorType,
  GET_ERRORS,
  TOGGLE_SIDEBAR,
  SET_ALERT,
  REMOVE_ALERT,
  Sidebar,
  SET_CURRENT,
  CLEAR_CURRENT,
  SET_CURRENT_2, CLEAR_CURRENT_2
} from "./types";

// RETURN ERRORS
export const returnErrors = (err: Error): ReturnErrorType => {
  return {
    type: GET_ERRORS,
    payload: err
  }
}

// TOGGLE SIDEBAR
export const toggleSidebar = (val: Sidebar) => (dispatch: Dispatch) => {
  dispatch({
    type: TOGGLE_SIDEBAR,
    payload: val
  })
}

// SET ALERT
export const setAlert = (msg: string, type: string, timeout: number = 5000) => (dispatch: Dispatch) => {
  const id = uuid()

  dispatch({
    type: SET_ALERT,
    payload: [{ msg, type, id }]
  })

  // Remove Alert
  setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout)
}

// SET CURRENT
export const setCurrent = (val: any) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_CURRENT,
    payload: val
  })
}
export const setCurrent2 = (val: any) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_CURRENT_2,
    payload: val
  })
}

// CLEAR CURRENT
export const clearCurrent = () => (dispatch: Dispatch) => {
  dispatch({
    type: CLEAR_CURRENT
  })
}
export const clearCurrent2 = () => (dispatch: Dispatch) => {
  dispatch({
    type: CLEAR_CURRENT_2
  })
}
