import { FoodCategory } from '../foodCategory/types'
import { Variant } from './variant/types'
import { Extra } from './extra/types'

export const GET_FOOD_LIST = 'GET_FOOD_LIST'
export const GET_NEW_FOOD_LIST = 'GET_NEW_FOOD_LIST'
export const GET_CUSTOMER_FOOD_LIST = 'GET_CUSTOMER_FOOD_LIST'
export const UPDATE_CUSTOMER_FOOD_LIST = 'UPDATE_CUSTOMER_FOOD_LIST'
export const GET_FOOD = 'GET_FOOD'
export const GET_TRASHED_FOOD_LIST = 'GET_TRASHED_FOOD_LIST'
export const ADD_FOOD = 'ADD_FOOD'
export const EDIT_FOOD = 'EDIT_FOOD'
export const SOFT_DELETE_FOOD = 'SOFT_DELETE_FOOD'
export const RESTORE_FOOD = 'RESTORE_FOOD'
export const PUBLISH_FOOD = 'PUBLISH_FOOD'
export const UNPUBLISH_FOOD = 'UNPUBLISH_FOOD'
export const TOGGLE_FEATURED_SETTING = 'TOGGLE_FEATURED_SETTING'
export const FOOD_UPLOAD_PHOTO = 'FOOD_UPLOAD_PHOTO'
export const FOOD_REMOVE_PHOTO = 'FOOD_REMOVE_PHOTO'
export const SET_PROMO = 'SET_PROMO'
export const UNSET_PROMO = 'UNSET_PROMO'
export const GET_FOOD_CATEGORY_LIST = 'GET_FOOD_CATEGORY_LIST'
export const GET_SEARCH_KEY_FOOD_CATEGORY_LIST = 'GET_SEARCH_KEY_FOOD_CATEGORY_LIST'
export const UPDATE_SEARCH_KEY_FOOD_CATEGORY_LIST = 'UPDATE_SEARCH_KEY_FOOD_CATEGORY_LIST'
export const GET_FOOD_HISTORY_LIST = 'GET_FOOD_HISTORY_LIST'

interface Promo {
  isPromo: boolean
  potongan: number
  startAt: number
  endAt: number
}

export interface Media {
  _id: string
  url: string
  path: string
}

export interface Flashsale {
  isFlashsale: boolean;
  startAt: number;
  endAt: number;
  max: number | any;
  price: number;
}

export interface Food {
  _id: string
  canPreorder: boolean
  createdAt: string
  cost: number
  deleted: boolean
  deskripsi: string
  extra: Extra[]
  flashsale?: Flashsale
  gambar: Media[]
  harga: number
  kategori: FoodCategory
  kitchen: string
  nama: string
  isFeatured: null | boolean
  isPreorder: boolean
  isPublished: boolean
  promo: Promo
  published: null | string
  short_deskripsi: string
  sku: string
  slug: string
  varian: Variant[]
  updatedAt: string
  _v: number
}

export interface NewFood {
  docs: Food[]
  totalDocs: number
  limit: number
  page: number
  totalPages: number
  pagingCounter: number
  hasPrevPage: boolean
  hasNextPage: boolean
  prevPage: number | null
  nextPage: number | null
}

export interface CustomerFoodType extends Food {
  numOfOrder: number
  foodNote: string
}

export interface CustomerFood {
  docs: CustomerFoodType[]
  totalDocs: number
  limit: number
  page: number
  totalPages: number
  pagingCounter: number
  hasPrevPage: boolean
  hasNextPage: boolean
  prevPage: number | null
  nextPage: number | null
}

export interface SearchKeyType {
  isSearching: boolean,
  searchKey: string,
  draft: number,
  isFeatured: number,
  isPromo: number,
  isPreorder: number
}

export interface Staff {
  verified: boolean;
  disabled: boolean;
  _id: string;
  deleted: boolean;
  nama: string;
  hp: string;
  email: string;
}

export interface FoodHistory {
  deleted: boolean;
  _id: string;
  staff: Staff;
  topic: string;
  from: number;
  to: number;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  food: Food;
}

export interface GetListType {
  type: typeof GET_FOOD_LIST
  payload: Food[]
}

export interface GetNewListType {
  type: typeof GET_NEW_FOOD_LIST
  payload: NewFood
}

export interface GetCustomerListType {
  type: typeof GET_CUSTOMER_FOOD_LIST
  payload: CustomerFood
}

export interface UpdateCustomerListType {
  type: typeof UPDATE_CUSTOMER_FOOD_LIST
  payload: CustomerFood
}

export interface AddFoodType {
  type: typeof ADD_FOOD
  payload: Food
}

export interface EditFoodType {
  type: typeof EDIT_FOOD
  payload: Food
}

export interface SetFoodPromoType {
  type: typeof SET_PROMO
  payload: Food
}

export interface UnsetFoodPromoType {
  type: typeof UNSET_PROMO
  payload: Food
}

export interface SoftDeleteFoodType {
  type: typeof SOFT_DELETE_FOOD
  payload: Food
}

export interface RestoreFoodType {
  type: typeof RESTORE_FOOD
  payload: Food
}

export interface GetDetailType {
  type: typeof GET_FOOD
  payload: Food
}

export interface GetTrashedListType {
  type: typeof GET_TRASHED_FOOD_LIST
  payload: Food[]
}

export interface PublishFoodType {
  type: typeof PUBLISH_FOOD
  payload: Food
}

export interface UnpublishFoodType {
  type: typeof UNPUBLISH_FOOD
  payload: Food
}

export interface FeaturedFoodType {
  type: typeof TOGGLE_FEATURED_SETTING
  payload: Food
}

export interface UploadFoodPhotoType {
  type: typeof FOOD_UPLOAD_PHOTO
  payload: Food
}

export interface RemoveFoodPhotoType {
  type: typeof FOOD_REMOVE_PHOTO
  payload: Food
}

export interface GetFoodCategoryListType {
  type: typeof GET_FOOD_CATEGORY_LIST
  key: string
  payload: NewFood
}

export interface FoodCategoryListType {
  [key: string]: NewFood
}

interface GetSearchKeyFoodCategoryListType {
  type: typeof GET_SEARCH_KEY_FOOD_CATEGORY_LIST
  key : string
  payload: SearchKeyType
}

interface UpdateSearchKeyFoodCategoryListType {
  type: typeof UPDATE_SEARCH_KEY_FOOD_CATEGORY_LIST
  key : string
  payload: SearchKeyType
}

export interface SearchKeyFoodCategoryListType {
  [key: string]: SearchKeyType
}

export interface GetFoodHistoryListType {
  type: typeof GET_FOOD_HISTORY_LIST,
  payload: FoodHistory[]
}

export interface FoodState {
  customer_food: CustomerFood
  new_food_list: NewFood
  food: Food | null
  food_list: Food[]
  food_trashed: Food[]
  food_categories: FoodCategoryListType
  search_key_food_categories: SearchKeyFoodCategoryListType
  food_history: FoodHistory[]
}

export type FoodActionType =
  | AddFoodType
  | EditFoodType
  | FeaturedFoodType
  | GetCustomerListType
  | GetDetailType
  | GetFoodCategoryListType
  | GetFoodHistoryListType
  | GetListType
  | GetSearchKeyFoodCategoryListType
  | GetTrashedListType
  | PublishFoodType
  | RemoveFoodPhotoType
  | RestoreFoodType
  | SetFoodPromoType
  | SoftDeleteFoodType
  | UnpublishFoodType
  | UnsetFoodPromoType
  | UpdateSearchKeyFoodCategoryListType
  | UploadFoodPhotoType
