import {
  ACTIVATE_PAYMENT_METHOD, ADD_PAYMENT_METHOD, DEACTIVATE_PAYMENT_METHOD, DELETE_PAYMENT_METHOD, EDIT_PAYMENT_METHOD,
  GET_ACTIVE_PAYMENT_LIST,
  GET_PAYMENT_LIST, GET_TRASHED_PAYMENT_LIST,
  PaymentState, RESTORE_PAYMENT_METHOD
} from "./types";

const initialState: PaymentState = {
  payment_list: [],
  active_payment_list: [],
  trashed_payment_list: []
}

export default function(state = initialState, action: any): PaymentState {
  switch (action.type) {
    case GET_PAYMENT_LIST:
      return {
        ...state,
        payment_list: action.payload
      }
    case GET_ACTIVE_PAYMENT_LIST:
      return {
        ...state,
        active_payment_list: action.payload.filter((i: any) => i.enabled)
      }
    case GET_TRASHED_PAYMENT_LIST:
      return {
        ...state,
        trashed_payment_list: action.payload
      }
    case ADD_PAYMENT_METHOD:
      return {
        ...state,
        payment_list: [...state.payment_list, action.payload]
      }
    case EDIT_PAYMENT_METHOD:
    case ACTIVATE_PAYMENT_METHOD:
    case DEACTIVATE_PAYMENT_METHOD:
      return {
        ...state,
        payment_list: state.payment_list.map(state => (state._id === action.payload._id ? action.payload : state))
      }
    case DELETE_PAYMENT_METHOD:
      return {
        ...state,
        payment_list: state.payment_list.filter(state => state._id !== action.payload._id),
        trashed_payment_list: [...state.trashed_payment_list, action.payload]
      }
    case RESTORE_PAYMENT_METHOD:
      return {
        ...state,
        trashed_payment_list: state.trashed_payment_list.filter(state => state._id !== action.payload._id),
        payment_list: [...state.payment_list, action.payload]
      }
    default:
      return state
  }
}
