import {
  EDIT_STOCK,
  GET_REPORT_STOCK_LIST,
  GET_STOCK_LIST,
  RESET_STOCK,
  SET_CURRENT_STOCK,
  STOCK_GENERATE,
  StockActionType,
  StockState
} from "./types";

const initialState: StockState = {
  stock_list: [],
  selected_stock: {
    variants: null,
    extras: null,
    category: null
  },
  report_stock_list: []
}

export default function(state = initialState, action: StockActionType): StockState {
  switch (action.type) {
    case STOCK_GENERATE:
    case GET_STOCK_LIST:
      return {
        ...state,
        stock_list: action.payload
      }
    case RESET_STOCK:
    case EDIT_STOCK:
      return {
        ...state,
        selected_stock: {
          variants: action.payload.varian,
          extras: action.payload.extra,
          category: action.payload.kategori
        }
      }
    case SET_CURRENT_STOCK:
      return {
        ...state,
        selected_stock: action.payload
      }
    case GET_REPORT_STOCK_LIST:
      return {
        ...state,
        report_stock_list: action.payload
      }
    default:
      return state
  }
}
