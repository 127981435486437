export const GET_FOOD_CATEGORY_LIST = 'GET_FOOD_CATEGORY_LIST'
export const GET_FOOD_CATEGORY_DETAIL = 'GET_FOOD_CATEGORY_DETAIL'
export const GET_TRASHED_LIST = 'GET_TRASHED_LIST'
export const ADD_FOOD_CATEGORY = 'ADD_FOOD_CATEGORY'
export const EDIT_FOOD_CATEGORY = 'EDIT_FOOD_CATEGORY'
export const SOFT_DELETE_FOOD_CTG = 'SOFT_DELETE_FOOD_CTG'
export const HARD_DELETE_FOOD_CTG = 'HARD_DELETE_FOOD_CTG'
export const RESTORE_FOOD_CTG = 'RESTORE_FOOD_CTG'

export interface FoodCategory {
  deleted: boolean
  _id: string
  judul: string
  keterangan: string
  createdAt: string
  updatedAt: string
  __v: number
  icon?: string
}

export interface GetListType {
  type: typeof GET_FOOD_CATEGORY_LIST
  payload: FoodCategory[]
}

export interface AddFoodCtgType {
  type: typeof ADD_FOOD_CATEGORY
  payload: FoodCategory
}

export interface EditFoodCtgType {
  type: typeof EDIT_FOOD_CATEGORY
  payload: FoodCategory
}

export interface SoftDeleteFoodCtgType {
  type: typeof SOFT_DELETE_FOOD_CTG
  payload: FoodCategory
}

export interface HardDeleteFoodCtgType {
  type: typeof HARD_DELETE_FOOD_CTG
  payload: string
}

export interface RestoreFoodCtgType {
  type: typeof RESTORE_FOOD_CTG
  payload: FoodCategory
}

export interface GetDetailType {
  type: typeof GET_FOOD_CATEGORY_DETAIL
  payload: FoodCategory
}

export interface GetTrashedListType {
  type: typeof GET_TRASHED_LIST
  payload: FoodCategory[]
}

export interface FoodCategoryState {
  food_ctg: FoodCategory[]
  selected_food_ctg: null | FoodCategory
  food_ctg_trashed: FoodCategory[]
}

export type FoodCategoryActionType =
  | GetListType
  | GetTrashedListType
  | GetDetailType
  | AddFoodCtgType
  | EditFoodCtgType
  | RestoreFoodCtgType
  | SoftDeleteFoodCtgType
  | HardDeleteFoodCtgType
