export const ADD_HOMESLIDER = 'ADD_HOMESLIDER'
export const DELETE_HOMESLIDER = 'DELETE_HOMESLIDER'
export const EDIT_HOMESLIDER = 'EDIT_HOMESLIDER'
export const GET_HOMESLIDER = 'GET_HOMESLIDER'
export const GET_HOMESLIDER_LIST = 'GET_HOMESLIDER_LIST'
export const GET_TRASHED_HOMESLIDER_LIST = 'GET_TRASHED_HOMESLIDER_LIST'
export const RESTORE_HOMESLIDER = 'RESTORE_HOMESLIDER'

export interface HomeSliderType {
  deleted: false,
  _id: string,
  judul: string,
  foto: string,
  caption: string,
  link: string,
  createdAt: string,
  updatedAt: string,
  __v: number
}

interface GetHomeSliderListType {
  type: typeof GET_HOMESLIDER_LIST
  payload: HomeSliderType[]
}

interface GetHomeSliderType {
  type: typeof GET_HOMESLIDER
  payload: HomeSliderType
}

interface EditHomeSliderType {
  type: typeof EDIT_HOMESLIDER
  payload: HomeSliderType
}

interface DeleteHomeSliderType {
  type: typeof DELETE_HOMESLIDER
  payload: HomeSliderType
}

interface AddHomeSliderType {
  type: typeof ADD_HOMESLIDER
  payload: HomeSliderType
}

interface GetTrashedHomeSliderListType {
  type: typeof GET_TRASHED_HOMESLIDER_LIST
  payload: HomeSliderType[]
}

interface RestoreHomeSliderType {
  type: typeof RESTORE_HOMESLIDER
  payload: HomeSliderType
}

export interface HomeSliderState {
  homeslider_list: HomeSliderType[]
  homeslider: HomeSliderType | null
  trashed_homeslider_list: HomeSliderType[]
}

export type HomeSliderActionType =
  | GetHomeSliderListType
  | GetHomeSliderType
  | EditHomeSliderType
  | DeleteHomeSliderType
  | AddHomeSliderType
  | GetTrashedHomeSliderListType
  | RestoreHomeSliderType
