import React from "react";
import { CModal, CModalBody, CModalHeader } from "@coreui/react";

type PaymentDetailProps = {
  formOpen : boolean,
  setFormOpen : any,
  initialState : any,
}

const PaymentDetail: React.FC<PaymentDetailProps> = ({ formOpen, setFormOpen, initialState}) => {
  return(
    <>
      <CModal
        show={formOpen}
        onClose={() => {
          setFormOpen(!formOpen)
        }}
        centered size={'lg'}
      >
        <CModalHeader closeButton>Payment Detail</CModalHeader>
        <CModalBody>
          <h5>{initialState.name}</h5>
          <br/>
          <table className={'table'}>
            <thead>
            <tr style={{textAlign: 'center'}}>
              <th>Nama</th>
              <th>Stakeholder</th>
              <th>No. Rekening</th>
            </tr>
            {initialState.detail !== undefined ?
              initialState.detail.map((i: any) => (
                <tr>
                  <td>{i.name}</td>
                  <td>{i.stakeholder}</td>
                  <td>{i.number}</td>
                </tr>
              ))
              :
              null
            }
            </thead>
          </table>
        </CModalBody>
      </CModal>
    </>
  )
}

export default PaymentDetail
