export const ADD_BLOG = 'ADD_BLOG'
export const ACTIVATE_BLOG = 'ACTIVATE_BLOG'
export const DEACTIVATE_BLOG = 'DEACTIVATE_BLOG'
export const EDIT_BLOG = 'EDIT_BLOG'
export const GET_BLOG_LIST = 'GET_BLOG_LIST'
export const GET_TRASHED_BLOG_LIST = 'GET_TRASHED_BLOG_LIST'

export interface BlogType {
  gambar: string[];
  read: number;
  comment: number;
  isPublished: boolean;
  tags: string[];
  deleted: boolean;
  _id: string;
  judul: string;
  deskripsi: string;
  short_deskripsi: string;
  published: string;
  konten: string;
  slug: string;
  kategori: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface GetBlogListType {
  type: typeof GET_BLOG_LIST
  payload: BlogType[]
}

interface GetTrashedBlogListType {
  type: typeof GET_TRASHED_BLOG_LIST
  payload: BlogType[]
}

interface AddBlogType {
  type: typeof ADD_BLOG
  payload: BlogType
}

interface EditBlogType {
  type: typeof EDIT_BLOG
  payload: BlogType
}

interface ActivateBlogType {
  type: typeof ACTIVATE_BLOG
  payload: BlogType
}

interface DeactivateBlogType {
  type: typeof DEACTIVATE_BLOG
  payload: BlogType
}

export interface BlogState {
  blog_list: BlogType[]
  trashed_blog_list: BlogType[]
}

export type BlogActionType =
  | GetBlogListType
  | GetTrashedBlogListType
  | AddBlogType
  | EditBlogType
  | ActivateBlogType
  | DeactivateBlogType
