export const ADD_SUPER_APP_HOME_SLIDER = 'ADD_SUPER_APP_HOME_SLIDER'
export const DELETE_SUPER_APP_HOME_SLIDER = 'DELETE_SUPER_APP_HOME_SLIDER'
export const EDIT_SUPER_APP_HOME_SLIDER = 'EDIT_SUPER_APP_HOME_SLIDER'
export const GET_SUPER_APP_HOME_SLIDER = 'GET_SUPER_APP_HOME_SLIDER'
export const GET_SUPER_APP_HOME_SLIDER_LIST = 'GET_SUPER_APP_HOME_SLIDER_LIST'
export const GET_SUPER_APP_TRASHED_HOME_SLIDER_LIST = 'GET_SUPER_APP_TRASHED_HOME_SLIDER_LIST'
export const RESTORE_SUPER_APP_HOME_SLIDER = 'RESTORE_SUPER_APP_HOME_SLIDER'

export interface Resources {
  thumbnail: string;
  small: string;
  medium: string;
  large: string;
}

export interface HomeSliderType {
  deleted: boolean;
  _id: string;
  judul: string;
  foto: string;
  caption: string;
  link: string;
  resources: Resources;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface GetHomeSliderListType {
  type: typeof GET_SUPER_APP_HOME_SLIDER_LIST
  payload: HomeSliderType[]
}

interface GetHomeSliderType {
  type: typeof GET_SUPER_APP_HOME_SLIDER
  payload: HomeSliderType
}

interface EditHomeSliderType {
  type: typeof EDIT_SUPER_APP_HOME_SLIDER
  payload: HomeSliderType
}

interface DeleteHomeSliderType {
  type: typeof DELETE_SUPER_APP_HOME_SLIDER
  payload: HomeSliderType
}

interface AddHomeSliderType {
  type: typeof ADD_SUPER_APP_HOME_SLIDER
  payload: HomeSliderType
}

interface GetTrashedHomeSliderListType {
  type: typeof GET_SUPER_APP_TRASHED_HOME_SLIDER_LIST
  payload: HomeSliderType[]
}

interface RestoreHomeSliderType {
  type: typeof RESTORE_SUPER_APP_HOME_SLIDER
  payload: HomeSliderType
}

export interface SuperAppHomeSliderState {
  super_app_home_slider_list: HomeSliderType[]
  trashed_super_app_home_slider_list: HomeSliderType[]
}

export type SuperAppHomeSliderActionType =
  | GetHomeSliderListType
  | GetHomeSliderType
  | EditHomeSliderType
  | DeleteHomeSliderType
  | AddHomeSliderType
  | GetTrashedHomeSliderListType
  | RestoreHomeSliderType
