import { AreaType } from '../auth/types'

export const GET_ROLE_LIST = 'GET_ROLE_LIST'
export const ADD_ROLE = 'ADD_ROLE'
export const EDIT_ROLE = 'EDIT_ROLE'
export const DELETE_ROLE = 'DELETE_ROLE'
export const ASSIGN_ROLE = 'ASSIGN_ROLE'
export const UNASSIGN_ROLE = 'UNASSIGN_ROLE'
export const GET_STAFF_LIST = 'GET_STAFF_LIST'
export const DELETE_STAFF = 'DELETE_STAFF'
export const TOGGLE_STAFF_STATUS = 'TOGGLE_STAFF_STATUS'
export const ADD_PERMISSION = 'ADD_PERMISSION'
export const REMOVE_PERMISSION = 'REMOVE_PERMISSION'

export interface Permission {
  _id: string
  name: string
}

export interface Privileges {
  _id: string
  staff: Staff | string
  role: Role
  area: AreaType
  createdAt: string
  updatedAt: string
  __v: number
}

export interface Staff {
  _id: string
  nama: string
  email: string
  deleted: boolean
  disabled: boolean
  password: string
  last_login: {
    ipAddress: string
    deviceType: string
    timestamp: string
  }
  verified: boolean
  privileges: Privileges[]
  createdAt: string
  updatedAt: string
}

export interface GetStaffType {
  type: typeof GET_STAFF_LIST
  payload: Staff[]
}

export interface DeleteStaffType {
  type: typeof DELETE_STAFF
  payload: Staff
}

export interface ToggleStaffStatusType {
  type: typeof TOGGLE_STAFF_STATUS
  payload: Staff
}

export interface Role {
  _id: string
  nama: string
  jobdesk: string
  permission: Permission[]
  _v: number
}

interface RoleUpdated extends Role {
  createdAt: string
  updatedAt: string
}

export interface RoleType {
  type: typeof GET_ROLE_LIST
  payload: Role[]
}

export interface DeleteRoleType {
  type: typeof DELETE_ROLE
  payload: string
}

export interface AddRoleType {
  type: typeof ADD_ROLE
  payload: RoleUpdated
}

export interface EditRoleType {
  type: typeof EDIT_ROLE
  payload: RoleUpdated
}

export interface AssignRoleType {
  type: typeof ASSIGN_ROLE
  payload: Staff
}

export interface UnassignRoleType {
  type: typeof UNASSIGN_ROLE
  payload: Staff
}

interface AddPermissionToRoleType {
  type: typeof ADD_PERMISSION
  payload: RoleUpdated
}

interface RemovePermissionToRoleType {
  type: typeof REMOVE_PERMISSION
  payload: RoleUpdated
}

export interface SuperAdminState {
  role_list: Role[]
  staff_list: Staff[]
}

export type SuperAdminActionType =
  | RoleType
  | AddRoleType
  | EditRoleType
  | DeleteRoleType
  | AssignRoleType
  | UnassignRoleType
  | GetStaffType
  | DeleteStaffType
  | ToggleStaffStatusType
  | AddPermissionToRoleType
  | RemovePermissionToRoleType
