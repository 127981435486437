import React from "react";
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader } from "@coreui/react";

type DeleteProps = {
  formOpen : boolean,
  setFormOpen : any,
  itemID : string,
  itemName : string,
  category : string,
  addCallback : any
}

const DeleteConfirmation: React.FC<DeleteProps> = ({ formOpen, setFormOpen, itemID, itemName, category, addCallback }) => {
  return (
    <CModal
      show={formOpen}
      onClose={() => {
        setFormOpen(!formOpen);
        addCallback(itemID, false);
      }}
      centered
    >
      <CModalHeader closeButton>
        Hapus {category}
      </CModalHeader>
      <CModalBody>
        <h6 style={{ textAlign: "center" }}>Apakah anda yakin akan menghapus {category.toLowerCase()}</h6>
        <h5 style={{ textAlign: "center" }}>{itemName} ?</h5>
      </CModalBody>
      <CModalFooter>
        <CButton
          color={"danger"}
          onClick={() => {
            setFormOpen(!formOpen);
            addCallback(itemID, true);
          }}
        >Hapus</CButton>
      </CModalFooter>
    </CModal>
  );
};

export default DeleteConfirmation
