export const ADD_SUPER_APP_BLOG = 'ADD_SUPER_APP_BLOG'
export const ACTIVATE_SUPER_APP_BLOG = 'ACTIVATE_SUPER_APP_BLOG'
export const DEACTIVATE_SUPER_APP_BLOG = 'DEACTIVATE_SUPER_APP_BLOG'
export const EDIT_SUPER_APP_BLOG = 'EDIT_SUPER_APP_BLOG'
export const GET_SUPER_APP_BLOG_LIST = 'GET_SUPER_APP_BLOG_LIST'
export const GET_SUPER_APP_TRASHED_BLOG_LIST = 'GET_SUPER_APP_TRASHED_BLOG_LIST'

export interface SuperAppBlogType {
  gambar: any[];
  read: number;
  comment: number;
  isPublished: boolean;
  deleted: boolean;
  _id: string;
  published: Date;
  judul: string;
  slug: string;
  deskripsi: string;
  konten: string;
  kategori: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  deletedAt?: any;
}

interface GetSuperAppBlogListType {
  type: typeof GET_SUPER_APP_BLOG_LIST
  payload: SuperAppBlogType[]
}

interface GetSuperAppTrashedBlogListType {
  type: typeof GET_SUPER_APP_TRASHED_BLOG_LIST
  payload: SuperAppBlogType[]
}

interface AddSuperAppBlogType {
  type: typeof ADD_SUPER_APP_BLOG
  payload: SuperAppBlogType
}

interface EditSuperAppBlogType {
  type: typeof EDIT_SUPER_APP_BLOG
  payload: SuperAppBlogType
}

interface ActivateSuperAppBlogType {
  type: typeof ACTIVATE_SUPER_APP_BLOG
  payload: SuperAppBlogType
}

interface DeactivateSuperAppBlogType {
  type: typeof DEACTIVATE_SUPER_APP_BLOG
  payload: SuperAppBlogType
}

export interface SuperAppBlogState {
  super_app_blog_list: SuperAppBlogType[]
  super_app_trashed_blog_list: SuperAppBlogType[]
}

export type SuperAppBlogActionType =
  | GetSuperAppBlogListType
  | GetSuperAppTrashedBlogListType
  | AddSuperAppBlogType
  | EditSuperAppBlogType
  | ActivateSuperAppBlogType
  | DeactivateSuperAppBlogType
